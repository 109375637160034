<div class="modal">
    <div class="modal__images">
        <img  src="../../../../../assets/images/modals/loading.gif">
    </div>
    <span class="modal__text modal__text--no_margin_bottom">
        "Great things come to those who wait!" 
    </span>
    <span class="modal__text">
        {{text}}
    </span>
</div>