import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { Season } from '../../models/season';
import { Team } from '../../models/team';
import { User } from '../../models/user';
import { SeasonService } from '../../services/season/season.service';
import { UserService } from '../../services/user/user.service';
import { TeamService } from '../../services/team/team.service';
import { EntityService } from '../../services/entity/entity.service';
import { Entity } from '../../models/entity';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language/language.service';

export const TOKEN_HEADER_TEAM = 'header-team';
export const TOKEN_HEADER_SEASON = 'header-season';
export const TOKEN_HEADER_ENTITY = 'header-entity';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements AfterViewInit {

  /* Usuario activo */
  user: User = new User();

  /* Datos relativos al usuario activo */
  teams: Team[] = [];
  teamSelected: Team = new Team();
  seasons: Season[] = [];
  seasonSelected: Season = new Season();
  entities: Entity[] = [];
  entitySelected: Entity = new Entity();
  languages = ['en', 'es'];
  languageSelected!: string;

  firstCharge = true;

  constructor(
    private core: Core,
    private teamService: TeamService,
    private seasonService: SeasonService,
    private entityService: EntityService,
    private userService: UserService,
    private translateService: TranslateService,
    private langService: LanguageService
  ) {
    this.user = this.core.getUserInfo();
    this.languageSelected = this.core.getUserInfo().lang!
  }

  ngAfterViewInit(): void {
    this.getEntities();
  }


  /**
   * Recogemos las entities del usuario.
   */
   getEntities() {
    this.entityService.getEntities().subscribe( res => {
      this.entities = res["data"];

      let header_entity = JSON.parse(localStorage.getItem(TOKEN_HEADER_ENTITY) as string)

      // tiene entity fav y no hay header entity
      if(this.user.entity_fav.id && !header_entity){
        let actual = this.entities[0];
        this.entitySelected = actual;
        this.core.setHeaderEntity(actual);
      }
      // hay header entity
      else if(header_entity){
        let i = 0;
        for(let z = 0; z < this.entities.length; z++){
          if(this.entities[z].id == header_entity.id){i = z; break;}
        }
        let actual = this.entities[i];
        this.entitySelected = actual;
      }
      // no hay header entity, hay teams y no hay entity fav
      // else if(this.teams.length != 0 && !this.user.entity_fav.id){
      //   let actual = this.entities[0];
      //   this.entitySelected = actual;
      //   this.core.setHeaderEntity(actual);
      // }
      //this.getSeasons();
      this.getTeams();
    })
  }

  /**
   * Recogemos los equipos del usuario.
   */
   getTeams() {
    this.teamService.getTeams(undefined, this.entitySelected.id).subscribe( res => {
      this.teams = res["data"];

      let header_team = JSON.parse(localStorage.getItem(TOKEN_HEADER_TEAM) as string)
      let are_team = 0;
      if(header_team){
        this.teams.forEach(elem=>{
          if(elem.id == header_team.id){are_team = 1}
        })
      }
      
      if(header_team && are_team){
        let i = 0;
        for(let z = 0; z < this.teams.length; z++){
          if(this.teams[z].id == header_team.id){i = z; break;}
        }
        let actual = this.teams[i];
        this.teamSelected = actual;
      }
      else if(this.teams.length != 0 && this.teamSelected.id != 0){
        let actual = this.teams[0];
        this.teamSelected = actual;
        this.core.setHeaderTeam(actual);
      }
      this.getSeasons();
    })
  }

  /**
   * Recogemos las temporadas del usuario dentro de un equipo seleccionado.
   */
  getSeasons() {
    this.seasonService.getSeasons(this.teamSelected.id).subscribe( res => {
      this.seasons = res["data"];

      // Recorro las seasons para comprobar si la que esta guardada en localstorage concuerda
      let seasonActive = this.core.getHeaderSeasonInfo()
      let seasonActiveChanged = false;
      this.seasons.forEach(season=>{
        if(season.id == seasonActive.id){season.active = true; seasonActiveChanged = true;}
      })
      // Si concuerda, marco todas las que no lo son como -> active = false
      if(seasonActiveChanged){
        this.seasons.forEach(season=>{
          if(season.id != seasonActive.id){season.active = false;}
        })
      }

      let actual = this.seasons.filter(season => season.active == true);
      if(actual.length != 0){
        this.seasonSelected = actual[0];
        this.core.setHeaderSeason(actual[0]);
        //this.getTeams(this.seasonSelected);
      }
      else{
        this.seasonSelected = this.seasons[0];
        this.core.setHeaderSeason(this.seasons[0]);
      }


      if(this.seasonSelected.id == 0){
        this.seasonSelected = this.seasons[0];
        this.core.setHeaderSeason(this.seasons[0]);
      }
    })
  }

  /**
   * Asignamos el team seleccionado para el core
   * @param team 
   */
   selectEntity(){
    this.core.setHeaderEntity(this.entitySelected);
    this.getTeams()
  }

  /**
   * Asignamos el team seleccionado para el core
   * @param team 
   */
   selectTeam(){
    this.core.setHeaderTeam(this.teamSelected);
    this.getSeasons();
  }

  /**
   * Asignamos la season seleccionada para el core
   * @param season 
   */
  selectSeason(){
    this.core.setHeaderSeason(this.seasonSelected);
    // this.getTeams()
  }

  changeLang(event: any){
    const lang = event.target.value;
    let user = this.core.getUserInfo();
    user.lang = lang;
    this.core.setUser(user)
    this.translateService.use(lang)
    this.langService.changeLanguage(lang)
  }

}
