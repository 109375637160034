export const es: { [key: string]: string } = {
    'GENERAL_PRACTICE':'Tests técnicos',
    'WORK_OUTS':'Test físicos',
    'COMMUNICATIONS':'Comunicaciones',
    'COMPETITIONS':'Competiciones',
    'TRIPS':'Viajes',
    'RECRUITMENT':'Reclutamiento',
    'WAREHOUSE':'Equipamiento',
    'STATS':'Estadísticas',
    'BUDGET':'Presupuesto',
    'SCHOLARSHIP_ARRANGEMENT':'Becas escolares',
    'New season':'Crear temporada',
    'Name':'Nombre',
    'Start date':'Fecha de inicio',
    'End date':'Fecha de fin',
    'Cancel':'Cancelar',
    'Accept':'Aceptar',
    'save':'guardar',
    'Edit season':'Editar temporada',
    'New team':'Crear equipo',
    'Institution':'Entidad',
    'Head coach':'Entrenador principal',
    'Photo':'Foto',
    'Players':'Jugadores',
    'Coaches':'Entrenadores',
    'No chat selected':'ningun chat seleccionado',
    'Create a new group':'crear un nuevo grupo',
    'Add members':'añadir miembros',
    'Create new group':'crear grupo',
    'Open chat':'Abrir chat',
    'New Trackman Url':'Nueva Url de Tech & Reports',
    'Trackman successfully assigned.':'Tech & Reports añadido correctamente',
    'Trackman': 'Tech & Reports',
    'All fields are required.':'Requiere todos los campos',
    'Received by': 'Recibido por',
    'Yes': 'Si',
    'No': 'No',
    'CredentialError': 'Email o contraseña incorrectos',
    'Date': 'Fecha',
    'Team': 'Equipo',
    'Type': 'Tipo',
    // Stats
    'No competitions are available for the selected filters.':'No hay competiciones disponibles para los filtros seleccionados.',
    // Dashboard - Livescoring
    'Pos.':'Pos.',
    'Hole':'Hoyo',
    'Today':'Hoy',
    'Total':'Total',
    'View competition':'Ver competición',
    // Dashboard - Calendar
    'Calendar':'Calendario',
    'List':'Lista',
    'Tournament':'Torneo',
    'Qualifier':'Clasificatorio',
    'Golf practice':'Entrenamiento técnico',
    'Workout':'Entrenamiento físico',
    'Trip':'Viaje',
    'There are no events for that day':'No hay eventos para el día seleccionado',
    // General practice
    'Time': 'Tiempo',
    'Duration': 'Duración',
    'Minutes': 'Minutos',
    'Create new plan': 'Crear nuevo plan',
    'Select players': 'Selecciona jugadores',
    'Exercises': 'Ejercicios',
    'Generate other plan': 'Generar otro plan',
    'Add exercises': 'Add exercises',
    'Random': 'Aleatorio',
    'Custom': 'Personalizado',
    'Session': 'Sesión',
    'Max file size 100MB': 'Tamaño máximo de archivo 100MB',
    'To pass the test': 'para pasar el test',
    'Edit': 'Editar',
    'Clone': 'Clonar',
    'Delete': 'Eliminar',
    'New exercise': 'Nuevo ejercicio',
    // Tooltips
    'GIR': 'Una estadística que mide el porcentaje de hoyos donde el golfista alcanza el green en el número de golpes prescrito. Específicamente, significa alcanzar el green en dos golpes menos que el par (por ejemplo, en dos golpes en un hoyo par 4 o en tres golpes en un hoyo par 5).',
    "Scoring from fairway": "Analiza todos los golpes desde el tee que cogen calle y registra el número de birdies, pares, bogeys y otras resultados obtenidos desde esa posición.",
    "Scoring from missing fairway": "Analiza todos los golpes desde el tee que no cogen calle y registra el número de birdies, pares, bogeys y otros resultados obtenidos desde esa posición.",
    "Misses from the tee": "Analiza todos los golpes desde el tee que no cogen calle y registra dónde falla el jugador, incluyendo rough, bunker, área de penalización o fuera de límites.",
    "Penalties from the tee": "Analiza todas las penalizaciones incurridas desde los golpes de salida, incluyendo los tiros que aterrizan en áreas de penalización, fuera de límites.",
    "Fringe miss": "Una estadística que registra cuando el golpe de aproximación de un golfista aterriza en el collarín, el área de hierba cortada cercana que bordea el green. Esto indica que casi se alcanza el green en regulación.",
    "Rough miss": "Una estadística que registra cuando el golpe de un golfista aterriza en el rough, el área de hierba más larga que rodea la calle y el green. Esto indica que se falló  el green.",
    "Bunker miss": "Una estadística que registra cuando el golpe de un golfista aterriza en un bunker (trampa de arena) y no resulta en un Green en Regulación (GIR). Esto indica que el golfista no alcanzó el green en el número de golpes prescrito.",
    "Other miss": "Una estadística que registra cuando el golpe de un golfista aterriza en cualquier área que no sea la calle, rough, bunker o green. Esto incluye obstáculos, áreas de penalización y fuera de límites.",
    "GIR accuracy from <95": "Una estadística que mide el porcentaje de veces que un golfista alcanza el green en regulación (GIR) desde distancias de 95 yardas o menos. Esto indica la precisión y exactitud del golfista con los golpes de aproximación desde distancias cortas",
    "GIR accuracy from <155": "Una estadística que mide el porcentaje de veces que un golfista alcanza el green en regulación (GIR) desde distancias entre 96 y 155 yardas. Esto indica la precisión y exactitud del golfista con los golpes de aproximación desde distancias medias.",
    "GIR accuracy from >155": "Una estadística que mide el porcentaje de veces que un golfista alcanza el green en regulación (GIR) desde distancias mayores a 155 yardas. Esto indica la precisión y exactitud del golfista con los golpes de aproximación desde largas distancias",
    "Proximity next shot from": "Mide la distancia promedio del primer putt cuando un golfista intenta salvar el par desde alrededor del green. Esto indica la proximidad del golfista al hoyo en su primer putt después de un chip o pitch.",
    "Number of 3 putts": "Mide el número de veces que un golfista necesita tres putts para embocar, expresado como un porcentaje del total de hoyos jugados.",
    "Putt made from": "Mide el porcentaje de putts embocados desde varias distancias. Esta estadística muestra tu tasa de éxito al convertir putts desde rangos específicos, indicando tu habilidad y precisión en los greens.",
    "Putt scoring": "Analiza el número de putts embocados para eagle, birdie, par y otras puntuaciones desde varias distancias. Esta estadística muestra tu tasa de éxito al lograr diferentes puntuaciones según la distancia del putt."
}