import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  
  @Input() type: string = ''
  @Input() title: string = '';
  @Input() text: string = '';

  @Output() closeEvent = new EventEmitter<number>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  close(value: number){
    this.closeEvent.emit(value)
  }

}
