<!--prueba cropper-->
<div class="dialog-header">
    <div class="dialog-back-button" (click)="dismissNoData()">
        <mat-icon svgIcon="cross" class="dialog-close-icon"></mat-icon>
    </div>
    <span class="dialog-title" translate="app.modals.uploadImage"></span>
</div>
<div class="dialog-container">
    <input type="file" (change)="fileChangeEvent($event)" id="imageInput" style="visibility: hidden;height: 0px;"/>
    <div class="upload-container">
        <!-- [resizeToWidth]="1080"
        [hideResizeSquares]="hideResize" -->
        <label for="imageInput" *ngIf="imageChangedEvent == '' && imageBase64String == ''" class="span-upload" translate="app.modals.upload"></label>
        <image-cropper class="cropper" *ngIf="imageChangedEvent != ''" style="max-height: 350px;"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="maintainRatio"
            [aspectRatio]="aspectRatio"
            format="webp"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <image-cropper class="cropper" *ngIf="imageBase64String != ''" style="max-height: 350px;"
            [maintainAspectRatio]="maintainRatio"
            [aspectRatio]="aspectRatio"
            format="webp"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (loadImageFailed)="loadImageFailed()"
            [imageBase64]="imageBase64String">
        </image-cropper>
    </div>
</div>
<div class="dialog-buttons" *ngIf="imageChangedEvent != ''">
    <label for="imageInput" class="fake-span">Change</label>
    <span (click)="dismiss()">Save</span>
</div>

<div class="dialog-buttons" *ngIf="imageBase64String != ''">
    <label for="imageInput" class="fake-span">Change</label>
    <span (click)="dismiss()">Save</span>
</div>

<!-- <img [src]="croppedImage" /> -->
