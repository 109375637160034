<div class="modal">
    <div class="modal__images">
        <img  src="../../../../../assets/images/modals/hand-susccess.png">
        <img class="modal__images__close cursor-pointer" (click)="close(0)" src="../../../../../assets/icons/x-circle.svg">
    </div>
    <span class="modal__title" *ngIf="title != ''">
        {{title}}
    </span>
    <span class="modal__text" [ngClass]="{'modal__text--no_title' : title == ''}">
        {{text}}
    </span>
</div>