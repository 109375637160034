import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Core } from '../../core/core';

@Injectable({
  providedIn: 'root'
})
export class ModuleGuard implements CanActivate, CanLoad {

  constructor(
    public core: Core,
    public router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      
    return true;
  }


  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(this.core.canLoadModules(route.data?.module)){
        return true;
      }
      this.router.navigate(['/login']);
      return false;
  
    // return true;
  }
}
