import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { FilterComponent } from './components/filter/filter.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { FormsModule } from '@angular/forms';
import { AlertMessageComponent } from './components/alert-message/alert-message/alert-message.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperComponent } from './components/cropper/cropper.component';
import { IntToDatePipe } from './pipes/int-to-date/int-to-date.pipe';
import { TwoDigitsPipe } from './pipes/two-digits/two-digits.pipe';
import { TranslatePipe } from './pipes/translate/translate.pipe';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedModalComponent } from './components/modals/shared-modal/shared-modal.component';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from './components/modals/success-modal/success-modal.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { StatsProgressBarComponent } from './components/stats/progress-bar/progress-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateDirective } from './translate/translate.directive';
import { FindLanguageFromKeyPipe } from './translate/find-languaje-from-key.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { CapitalizePipe } from './pipes/capitalice/capitalice.pipe';
import { FormatDateByLangPipe } from './pipes/formatDateByLang/format-date-by-lang.pipe';


@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    FilterComponent,
    TabsComponent,
    AlertMessageComponent,
    ConfirmComponent,
    CropperComponent,
    IntToDatePipe,
    TwoDigitsPipe,
    TranslatePipe,
    CapitalizePipe,
    ToggleButtonComponent,
    ProgressBarComponent,
    SharedModalComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    SuccessModalComponent,
    LoadingModalComponent,
    StatsProgressBarComponent,
    TranslateDirective,
    FindLanguageFromKeyPipe,
    FooterComponent,
    FormatDateByLangPipe,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    MatMenuModule,
    ImageCropperModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    FilterComponent,
    TabsComponent,
    CropperComponent,
    IntToDatePipe,
    TwoDigitsPipe,
    TranslatePipe,
    CapitalizePipe,
    FormatDateByLangPipe,
    ToggleButtonComponent,
    ProgressBarComponent,
    StatsProgressBarComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    LoadingModalComponent,
    TranslateModule,
    TranslateDirective,
    FindLanguageFromKeyPipe,
    FooterComponent,
  ]
})
export class SharedModule { }
