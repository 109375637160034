import { ApplicationModule } from './applicationModule';
import { Club } from './club';
import { Entity } from './entity';
import { Team } from './team';

export class User{
    id: number = 0;
    email!: string;
    name!: string;
    surname!: string;
    birth_date?: number;
    //campos db
    sex?: string;
    student_id?: string;
    category?: number;
    hand?: string;
    country?: string;
    country_id?: number;
    state?: string;
    state_id?: number;
    city?: string;
    city_id?: number;
    weight_lb?: number;
    weight_kg?: number;
    height_feet?: number;
    height_inches?: number;
    height_cm?: number;
    passport?: string;
    passport_url?: string;
    driver_license?: string;
    driver_license_url?: string;
    // fin campos db
    telephone?: number;
    lang?: string;
    distance?: string;
    photo?: string;
    creation_date?: number;
    role?: number;
    modules?: ApplicationModule[];
    player!: {
        clubs: Club[],
        student_id: string,
        country: string,
        country_id: number,
        state: string,
        state_id: number,
        city: string,
        city_id: number,
        weight_kg: number,
        weight_lb: number,
        height_cm: number,
        height_feet: number,
        height_inches: number,
        passport: string,
        passport_url: string,
        driver_license: string,
        driver_license_url: string,
        trackman: any[],
        schedule: any[],
        academics: any[],
        tutors: any[];
    };
    entities!: Entity[];
    entity_fav!: Entity;
    teams?: Team[];
    only_app?: boolean;
    personal_stats?: boolean;
    active?: boolean;
    freelance?: boolean;
    jwt?: string;
    password?: string;
    selected!: boolean;
}
