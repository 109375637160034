<div class="dialog-header">
    <span class="dialog-title">{{ data.title }}</span>
</div>
<div class="dialog-container">
    <span>{{ data.text }}</span>
</div>
<div class="dialog-buttons">
    <span *ngFor="let button of data.buttons" 
          (click)="handleButtonClick(button)">
      {{ button }}
    </span>
</div>
