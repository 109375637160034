import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { Club } from '../../models/club';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private core: Core
    ) { }
    
  /**
   * Devuelve los datos de un usuario.
   * 
   * @param id id del usuario
   * @returns JSON
   */
  getUser(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/users/' + id);
  }

  /**
  * Cargar todas las competiciones que tiene un jugador
  *
  * @param  id id del user
  * @returns Json
  */
  getUserCompetitions(id: number, season: number = 0, stats: boolean = false):Observable<any> {
    let param = ''
    if(season != 0 || stats){
      param = '?'
      if (stats) {
        param += '&stats=' + stats;
      }
      if (season) {
        param += '&season=' + season;
      }
    }

    return this.http.get(environment.apiUrl + '/users/' + id + '/competitions' + param )
  }


  /**
   * Crea un nuevo usuario.
   * 
   * @param user usuario que crearemos
   * @returns JSON
   */
  postUser(user: User, entity?:number, season?:number): Observable<any> {
    let query = '';
    if(season || entity) {
      query = '?';
      if (season) {
        query += '&season=' + season;
      }
      if (entity) {
        query += '&entity=' + entity;
      }
    }

    return this.http.post(environment.apiUrl + '/users' + query, { user });
  }

  /**
   * Edita un usuario.
   * 
   * @param user usuario que editaremos
   * @returns JSON
   */
  putUser(user: User): Observable<any> {
    return this.http.put(environment.apiUrl + '/users/' + user.id, { user });
  }

  /**
   * Elimina un usuario.
   * 
   * @param id id del usuario
   * @returns JSON
   */
  deleteUser(id: number): Observable<any> {
    return this.http.delete(environment.apiUrl + '/users/' + id);
  }

  /**
   * Cambia la contraseña de un usuario.
   * 
   * @param id id del usuario
   * @param password password nueva
   * @returns JSON
   */
  changePassword(id: number, password: string): Observable<any> {
    return this.http.put(environment.apiUrl + '/users/' + id + '/password', {password});
  }

  /**
   * Devuelve todos los usuarios disponibles para el usuario que realiza la consulta.
   * 
   * @returns JSON
   */
  getUsers(season?:number, team?:number, role?:number, entity?:number): Observable<any> {
    let query = '';
    if(season || team || role || entity) {
      query = '?';
      if (season) {
        query += '&season=' + season;
      }
      if (team) {
        query += '&team=' + team;
      }
      if (role) {
        query += '&role=' + role;
      }
      if (entity) {
        query += '&entity=' + entity;
      }
    }
    
    return this.http.get(environment.apiUrl + '/users'+query);
  }

  /**
   * Cargar todas las temporadas del usuario activo.
   *
   * @returns Json
   */
  getSeasons(): Observable<any> {
    return this.http.get(environment.apiUrl + '/users/' + this.core.getUserInfo().id + '/seasons');
  }

  /**
   * Cargar todas las temporadas del usuario seleccionado.
   *
   * @param id id del usuario seleccionado
   * @returns Json
   */
  getUserSeasons(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/users/' + id + '/seasons');
  }

  /**
  * Carga los equipos de una season.
  * 
  * @param id id de la season
  * @returns Json
  */
  getSeasonTeams(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/users/' + this.core.getUserInfo().id + '/teams/season/' + id);
  }

  /**
  * Carga las entidades de una season del usuario activo.
  * 
  * @param season id de la temporada.
  * @returns Json
  */
  getEntities(season: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/users/' + this.core.getUserInfo().id + '/entities?season=' + season);
  }

  /**
  * Carga las entidades de una season del usuario seleccionado.
  * 
  * @param user id del usuario.
  * @param season id de la temporada.
  * @returns Json
  */
  getUserEntities(user: number, season: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/users/' + user + '/entities?season=' + season);
  }

  /**
   * Guarda la entidad recibida al usuario seleccionado en la temporada dada.
   * 
   * @param user id del usuario seleccionado
   * @param season id de la temporada
   * @param freelance booleano que indica si es freelance o no
   * @param entity id de la entidad seleccionada
   * @returns Json
   */
  postSeasonEntity(user: number, season: number, freelance: number, entity: number) {
    return this.http.post(environment.apiUrl + '/users/' + user + '/seasons-entities', {season: season, freelance: freelance, entity: entity})
  }

  /**
   * Cargamos los entrenadores de una entidad dada.
   * 
   * @param entity id de la entidad
   * @returns Json
   */
  getCoaches(entity: number, season?:number): Observable<any> {
    let query = '';
    if (season) {
      query += '&season=' + season;
    }
    return this.http.get(environment.apiUrl + '/users?role=2&entity=' + entity  + query);
  }

  /**
   * Cargamos los jugadores de una entidad dada.
   * 
   * @param entity id de la entidad
   * @returns Json
   */
  getPlayers(entity: number, season?:number): Observable<any> {
    let query = '';
    if (season) {
      query += '&season=' + season;
    }

    return this.http.get(environment.apiUrl + '/users?role=3&entity=' + entity + query);
  }


  getUsersAvailables(team: number, season:number, role:number){
    let query = '';
    if (role) {
      query += '?role=' + role;
    }

    ///users-avalaibles/{team}/{season}
    return this.http.get(environment.apiUrl + '/users-avalaibles/' + team + '/' + season + query);
  }

  /**
   * Get de palos de un jugador.
   * 
   * @param user id del usuario
   * @returns Json
   */
  getClubs(user: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/users/' + user + '/clubs');
  }

  /**
   * Asignamos un nuevo palo a un jugador.
   * 
   * @param user id del usuario
   * @param club palo a crear
   * @returns Json
   */
  postClub(user: number, club: Club): Observable<any> {
    return this.http.post(environment.apiUrl + '/users/' + user + '/clubs', club);
  }

  /**
   * Actualizamos un palo de un jugador.
   * 
   * @param user id del usuario
   * @param club palo a guardar
   * @returns Json
   */
  putClub(user: number, club: Club): Observable<any> {
    return this.http.put(environment.apiUrl + '/users/' + user + '/clubs/' + club.id, club);
  }

  /**
   * Eliminamos un palo de un jugador.
   * 
   * @param user id del usuario
   * @param club palo a guardar
   * @returns Json
   */
  deleteClub(user: number, club: Club): Observable<any> {
    return this.http.delete(environment.apiUrl + '/users/' + user + '/clubs/' + club.id);
  }

  /**
   * Guardamos un nuevo documento al usuario dado.
   * 
   * @param user id del usuario
   * @param name nombre del fichero
   * @param file fichero en base64
   * @param type tipo del fichero
   * @returns Json
   */
  postDocument(user: number, name: string, file: any, type: number, trackman_type?: number): Observable<any> {
    let document = {};
    if (trackman_type) {
      document = {
        name: name,
        base64: file,
        type: type,
        trackman_type: trackman_type
      }
    } else {
      document = {
        name: name,
        base64: file,
        type: type
      }
    }
    return this.http.post(environment.apiUrl + '/users/' + user + '/documents', document)
  }

  /**
   * Borramos un archivo de un usuario dado.
   * 
   * @param user id del usuario
   * @param file id del fichero a borrar
   * @returns Json
   */
  deleteDocument(user: number, file: number): Observable<any> {
    return this.http.delete(environment.apiUrl + '/users/' + user + '/documents/' + file)
  }

  /**
   * Guardamos un nuevo documento al usuario dado.
   * 
   * @param user id del usuario
   * @param document documento a subir
   * @returns Json
   */
  postDocumentProfile(user: number, document: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/users/' + user + '/documents-profile', document)
  }

  /**
   * Guardamos una nueva url de trackman
   * 
   * @param user id del usuario
   * @param document documento a subir
   * @returns Json
   */
  postTrackmanUrl(user: number, trackman_url: string, name: string): Observable<any> {
    let trackman = {
      name: name,
      url: trackman_url,
      trackman_type: 3,
      type: 3
    }
    return this.http.post(environment.apiUrl + '/users/' + user + '/trackman', trackman)
  }

}
