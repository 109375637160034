export class Team{
    id!: number;
    entity_name!: string;
    entity_id!: number;
    name!: string;
    head_coach_name!: string;
    head_coach_id!: number;
    creation_date?: number;
    coachs!: any[];
    players!: any[]; //falta definir player
    photo?: string;
}