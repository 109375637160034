import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Core } from '../core/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private core: Core, private router: Router) {

    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    // _error es el objeto que tiene description y type
                    // description es la descripcion del error
                    // type el tipo de error
                    let _error = error.error.error;

                    console.log(error.error.error)
                    if (error.error instanceof ErrorEvent) {
                        console.log('This is client side error');
                        errorMsg = `Error: ${error.error.message}`;
                    } else {
                        console.log('This is server side error');
                        errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                    }
                    if (error.status == 403 || error.status == 401) {
                        // this.core.logOut();
                        this.router.navigate(['/dashboard']);
                    }
                    console.log(errorMsg);
                    return throwError(errorMsg);
                })
            )
    }
}