<div class="login">
    <!-- <div class="login-photo">
        <!-- <video autoplay="true" loop muted = "true" poster preload="true" class="video-login">
            <source src="https://zaaxsports.com/video-zaax.mp4">
        </video> -->
        <!-- <img src="assets/images/inbounds_login.jpeg"/> -->
    <!-- </div> -->


    <div class="login-user">
        <div class="login-data">
            <div class="logo">
                <img class="login-logo" src="assets/icons/logo.svg">
                <div>
                    <span class="login-logo-text" translate="app.login.greetings"></span>
                    <span class="login-logo-text login-logo-text--bold">
                        InBounds Golf
                    </span>
                </div>
            </div>
            <div class="login-input">
                <span class="input-label" translate="app.login.email"></span>
                <input class="input-box" type="text" placeholder="Email@gmail.com" [(ngModel)]="email" (click)="loginError = false"/>
            </div>
            <div class="login-input">
                <div class="input-labels">
                    <span class="input-label" translate="app.login.password"></span>
                    <a class="input-label-pass" (click)="recovery()" translate="app.login.forgotPassword"></a>
                </div>
                <input class="input-box" id="password" type="password" placeholder="Enter password"
                    [(ngModel)]="password" (keydown)="onKeydown($event)"  (click)="loginError = false"/>
                <mat-icon svgIcon="eye-opened" class="input-icon" (click)="togglepass()"></mat-icon>
            </div>

            <div class="login-input" *ngIf="loginError">
                <span class="error-label" translate="app.login.loginError"></span>
            </div>

            <a (click)="login()" class="login-button" translate="app.login.signIn"></a>

            <span class="copyright">        
                &copy; 2023 Made with ❤️ by InBounds team
            </span>

            <div class="terms-container">
                <div class="terms-and-service cursor-pointer">
                    <span (click)="goToPrivacy()">Privacy Policy</span>
                    <span (click)="goToCookie()">Cookie Policy</span>
                    <span (click)="goToTerms()">Terms of Service</span>
                    <span (click)="goToLegal()">Legal Notice</span>
                </div>
            </div>
        </div>
    </div>
</div>