import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit {

  aspectRatio: number = 1 / 1;
  hideResize: boolean = true;
  maintainRatio: boolean = true;
  name: string = '';

  imageChangedEvent: any = '';
  croppedImage: any = '';
  loadedImage:any = '';

  imageBase64String: any = '';

  arrowUpIcon = "../../../assets/icons/arrow-big-up.svg";
  checkIcon = "../../../assets/icons/check.svg";

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {aspectRatio?: number, hideResize?: boolean, maintainRatio?: boolean, name?: string, image?: any}
  ) {
    if (data.name) {
      this.name = data.name;
    }
    if (data.aspectRatio) {
      this.aspectRatio = data.aspectRatio;
    }
    if (data.hideResize == false && data.maintainRatio == false) {
      this.hideResize = data.hideResize
      this.maintainRatio = data.maintainRatio
    }
    if(data.image){
      // this.imageChangedEvent = data.image;
      this.imageBase64String = data.image
      // this.loadedImage = data.image;
      console.log('entra bien')
    }
  }

  ngOnInit() {
  }

  //cropper
  fileChangeEvent(event: any): void {
    this.imageBase64String = ''
    this.imageChangedEvent = event;
  }

  imageCropped(image: any) {
    this.croppedImage = image;
    if (this.name) {
      this.croppedImage.name = this.name;
    }
  }

  imageLoaded(event: any) {
    // this.imageBase64String = ''
    this.loadedImage = event;
  }

  loadImageFailed() {
    // show message
  }

  dismiss(){
    this.dialogRef.close(this.croppedImage);
  }

  dismissNoData(){
    this.dialogRef.close();
  }

}
