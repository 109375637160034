import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { Team } from '../../models/team';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    private http:HttpClient,
    private core: Core
  ) { }

  /**
   * Creamos un equipo dado.
   * 
   * @param team equipo a crear
   * @returns Json
   */
  postTeam(team: Team): Observable<any> {
    return this.http.post(environment.apiUrl+'/teams', {team});
  }

  /**
   * Editamos un equipo dado.
   * 
   * @param team equipo a crear
   * @returns Json
   */
   putTeam(team: Team, season?:number): Observable<any> {
    let query = '';
    if (season) {
      query += '?season=' + season;
    }

    return this.http.put(environment.apiUrl+'/teams/' + team.id + query, {team});
  }

  /**
   * Borramos un equipo dado.
   * 
   * @param team id del equipo a borrar
   * @returns Json
   */
  deleteTeam(team: number): Observable<any> {
    return this.http.delete(environment.apiUrl+'/teams/' + team);
  }

  /**
   * Carga los jugadores asociados al coach.
   * 
   * @param season
   * @param team
   * @returns
  */
   getPlayers(season: number, team: number, role?: number): Observable<any> {
    return this.http.get(environment.apiUrl+'/users?role=3&season='+season+'&team='+team);
  }

  /**
   * Carga los teams que puede ver un user
   * 
   * @param season
   * @param entity
   * @returns
  */
  getTeams(season?: number, entity?: number): Observable<any> {
    let params = '';
    if (season != undefined) {
      params += 'season='+season+'&';
    }
    if (entity != undefined) {
      params += 'entity='+entity+'&';
    }
    if (season || entity) {
      return this.http.get(environment.apiUrl+'/teams?'+params);
    } else {
      return this.http.get(environment.apiUrl+'/teams');
    }
  }

  /**Carga los datos de un team en ase a su id */
  getTeam(id: number,season?:number): Observable<any> {
    let query = '';
    if(season) {
      query = '?season='+season;
    }
    return this.http.get(environment.apiUrl+'/teams/'+id+query);
  }
  

}
