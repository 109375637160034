import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  
  @Input() type: string = ''
  @Input() title: string = '';
  @Input() text: string = '';

  @Output() closeEvent = new EventEmitter<number>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  close(value: number){
    this.closeEvent.emit(value)
  }

}
