import { Injectable, ɵɵelementContainerStart } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';

export const TOKEN_KEY = 'my-token';
export const TOKEN_USER = 'current-user';
export const TOKEN_USER_ID = 'current-user-id';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private core: Core
  ) { }
 
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.core.getIsAuthenticated().pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        if (isAuthenticated) {
          this.core.setUser(JSON.parse(localStorage.getItem(TOKEN_USER) as string));
          //this.getColors();
          this.getMenuActive();
          return true;
        } else {          
          this.router.navigateByUrl('/login');
          return false;
        }
      })
    );
  }

  getColors() {
    let user = this.core.getUserInfo();
    if (user.entity_fav) {
      (<HTMLElement>document.querySelector(':root')).style.setProperty('--primary', user.entity_fav.primary_color);
      let primaryBg = user.entity_fav.primary_color + '22';
      (<HTMLElement>document.querySelector(':root')).style.setProperty('--primary_bg', primaryBg);
      (<HTMLElement>document.querySelector(':root')).style.setProperty('--secondary', user.entity_fav.secondary_color);
      let secondaryBg = user.entity_fav.secondary_color + '50';
      (<HTMLElement>document.querySelector(':root')).style.setProperty('--secondary_bg', secondaryBg);
      (<HTMLElement>document.querySelector(':root')).style.setProperty('--tertiary', user.entity_fav.tertiary_color);
      let tertiaryBg = user.entity_fav.tertiary_color + '22';
      (<HTMLElement>document.querySelector(':root')).style.setProperty('--tertiary_bg', tertiaryBg);
      let logo = 'url(' + user.entity_fav.logo + ')';
      (<HTMLElement>document.querySelector(':root')).style.setProperty('--logo', logo);

    }
  }


  getMenuActive() {
    setTimeout( () => {
      switch(true) {
        case this.router.url.includes('dashboard'): {
          this.core.setMenuActive('home');
          break;
        }
        case this.router.url.includes('players'): {
          this.core.setMenuActive('players');
          break;
        }
        case this.router.url.includes('practices/sessions'): {
          this.core.setMenuActive('sessions');
          break;
        }
        case this.router.url.includes('practices/exercises'): {
          this.core.setMenuActive('exercises');
          break;
        }
        case this.router.url.includes('workouts'): {
          this.core.setMenuActive('workouts');
          break;
        }
        case this.router.url.includes('competitions'): {
          this.core.setMenuActive('competitions');
          break;
        }
        case this.router.url.includes('stats'): {
          this.core.setMenuActive('stats');
          break;
        }
        case this.router.url.includes('calendar'): {
          this.core.setMenuActive('calendar');
          break;
        }
        case this.router.url.includes('trips'): {
          this.core.setMenuActive('trips');
          break;
        }
        case this.router.url.includes('chat'): {
          this.core.setMenuActive('chat');
          break;
        }
        case this.router.url.includes('recruitment'): {
          this.core.setMenuActive('recruitment');
          break;
        }
        case this.router.url.includes('equipment'): {
          this.core.setMenuActive('equipment');
          break;
        }
        case this.router.url.includes('configuration'): {
          this.core.setMenuActive('configuration');
          break;
        }
      }
    }, 0);
  }
}
