<div class="modal">
    <div class="modal__images">
        <img  src="../../../../../assets/images/modals/hand-confirm.png">
    </div>
    <span class="modal__title" *ngIf="title != ''">
        {{title}}
    </span>
    <span class="modal__text" [ngClass]="{'modal__text--no_title' : title == ''}">
        {{text}}
    </span>

    <div class="modal__buttons_container">
        <a class="button cursor-pointer" (click)="close(0)" [ngClass]="{'button__primary' : selectedButton == 'NO', 'button__secondary' : selectedButton != 'NO'}">
            {{ textButtons == null ? 'No': textButtons[0]}}
        </a>
        <a class="button cursor-pointer" (click)="close(1)" [ngClass]="{'button__primary' : selectedButton == 'YES', 'button__secondary' : selectedButton != 'YES'}">
            {{ textButtons == null ? 'Yes': textButtons[1]}}
        </a>
    </div>
</div>