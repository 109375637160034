import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { MixpanelBaseService } from '../mixpanel-base.service';
import { GeneralEventsTrackingInterface } from '../interface/mixpanel.interface'

export const TOKEN_COLLAPSED_MENU = 'collapsed-menu';

@Injectable({
  providedIn: 'root',
})
export class MixpanelGeneralEventsTrackingService extends MixpanelBaseService implements GeneralEventsTrackingInterface {
  constructor() {
    super();
  }

  trackPageName(pageName: string, currentUrl: string): void {
    this.initMixpanel();

    let collapsedJson = JSON.parse(localStorage.getItem(TOKEN_COLLAPSED_MENU) as string)

    let menuIsOpened = true;
    if(collapsedJson){
      menuIsOpened = !collapsedJson.collapsed;
    }
    
    mixpanel.track('Page view', {
      'Nombre pantalla': pageName,
      URL: this.deleteQueryParamsFromURL(currentUrl),
      'Menu abierto': menuIsOpened
    });
  }

}
