import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input() type: string = ''
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() selectedButton?: string = '';
  @Input() textButtons?: any[any] = []

  @Output() closeEvent = new EventEmitter<number>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  close(value: number){
    this.closeEvent.emit(value)
  }

}
