import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { Season } from '../../models/season';

@Injectable({
  providedIn: 'root'
})
export class SeasonService {

  constructor(
    private http:HttpClient,
    private core: Core
  ) { }

  /**
   * Cargar los datos de una season
   * @param id id de la season
   * @returns Json
   */
  getSeason(id: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/seasons/' + id);
  }

  /**
   * Crear una season
   * @param season season a crear
   * @returns Json
   */
  postSeason(season: Season) {
    return this.http.post(environment.apiUrl + '/seasons', {season});
  }

  /**
   * Editamos una season
   * @param season season a editar
   * @returns Json
   */
  putSeason(season: Season) {
    return this.http.put(environment.apiUrl + '/seasons/' + season.id, {season});
  }

  /**
   * Cargar todas las seasons
   *
   * @returns Json
   */
  getSeasons(team?:number): Observable<any> {
    let params = '';
    if (team != undefined) {
      params += '?team='+team+'&';
    }

    return this.http.get(environment.apiUrl + '/seasons' + params);
  }

  /**
   * Cargar una season en base a una fecha 
   * @param date fecha (number)
   * @returns Json
   */
  getSeasonDate(date: number) {
    return this.http.get(environment.apiUrl + '/seasons/date/' + date);
  }

  /**
   * Cargar los datos de una season
   *
   * @returns Json
   */
  getActualSeason() {
    return this.http.get(environment.apiUrl + '/actual-season');
  }
}
