import { FocusTrap } from '@angular/cdk/a11y';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  host: {'class': 'circle-wrap'}
})
export class ProgressBarComponent implements OnInit {

  @Input() progress: number = 0;
  @Input() id: number = 0;

  constructor() { }
  
  ngOnInit(): void {
    let deg = this.progress / 25 * 45 + 'deg';
    let style = document.createElement('style');
    style.type = 'text/css';
    let keyFrames = '@keyframes fill' + this.id + '{ 0% { transform: rotate(0deg); } 100% { transform: rotate(' + deg + '); }';
    style.innerHTML = keyFrames;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  ngAfterViewInit(): void {
    setTimeout( () => {
      let deg = this.progress / 25 * 45 + 'deg';
      for (let i = 0; i < document.getElementsByClassName('progress_' + this.id)!.length; i++) {
        document.getElementsByClassName('progress_' + this.id)[i]!.setAttribute('style', 'animation: fill' + this.id + ' ease-in-out 1s forwards; transform: rotate(' + deg + ');');
      }
      for (let i = 0; i < document.getElementsByClassName('progress_' + this.id + '_fill')!.length; i++) {
        document.getElementsByClassName('progress_' + this.id + '_fill')[i]!.setAttribute('style', 'animation: fill' + this.id + ' ease-in-out 1s forwards;');
      }
    }, 800);
  }

}
