import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Core } from '../../core/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  /* Valores recibidos para mostrar los tabs */
  @Input() tabs: string[] = [];
  @Input() tabActive: string = ''
  /* Evento emisor para controlar el tab seleccionado */
  @Output() tab = new EventEmitter<any>();

  role!:number|undefined;

  constructor(private core: Core) { }

  ngOnInit(): void {
    this.role = this.core.getUserInfo().role;
    if(this.tabActive == ''){
      this.tabActive = this.tabs[0];
    }
    this.tab.emit(this.tabActive);
  }

  emitTab(tab: string) {
    this.tab.emit(tab);
  }

  tabIsInArray(tab: string){
    if(this.tabs.includes(tab)){return true;}
    else{return false;}
  }

}
