import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarModule, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AlertMessageComponent } from 'src/app/shared/components/alert-message/alert-message/alert-message.component';
import { Core } from 'src/app/shared/core/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  /**parametros del login */
  email: string = '';
  password: string = '';

  loginError: Boolean = false;

  /**alert del snackbar */
  snackBarRef: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router, 
    private snackbar:MatSnackBar,
    private core: Core,
  ) { }

  ngOnInit(): void {
    this.core.getIsAuthenticated().subscribe(res => {
      if (res) { this.router.navigate(['/dashboard']); }
    })

  }

  togglepass() {
    var x = document.getElementById("password")
    if (x != null) {
      if (x.getAttribute('type') === "password") {
        x.setAttribute('type', 'text');
      } else {
        x.setAttribute('type', 'password');
      }
    }
  }

  login() {
    if (this.validateEmail(this.email) == true) {
      this.authenticationService.login(this.email, this.password).subscribe(res => {
        if(res.status == 404){
          this.loginError = true;
        }
        else{
          this.router.navigate(['/dashboard']);
        }
      }, error=>{
        this.loginError = true;
      });
    } else {
      this.messageError(this.validateEmail(this.email));
    };
  }

  validateEmail(email: string): any {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      return true;
    } else {
      return 'Email invalido';
    }
  }

  recovery() {

  }

  onKeydown(event: any) {
    if (event.key === "Enter") {
      this.login();
    }
  }

  messageError(message: string) {
    this.snackbar.openFromComponent(AlertMessageComponent, {
      data: {
        message: message,
        type: 'error'
      },
      panelClass: ['snack-error'],
    });
  }

  goToPrivacy(){
    window.open("https://coachinbounds.com/home/privacy-policy/")
  }

  goToCookie(){
    window.open("https://coachinbounds.com/home/cookie-policy/")
  }

  goToTerms(){
    window.open("https://coachinbounds.com/home/terms-of-service/")
  }

  goToLegal(){
    window.open("https://coachinbounds.com/home/legal-notice/")
  }

}
