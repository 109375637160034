// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { User } from "src/app/shared/models/user";

export const environment = {
  production: false,
  apiUrl: 'https://dev-api.coachinbounds.com/app',
  userToken: '',
  user: new User(),
  userId: '',
  userDistance: '',
  mixpanelToken: 'd5f3f4f7bb8cc5df5fe88276dee4c7ce',
  hotjarTrackingCode: 3818606,
  hotjarVersion: 6,
  demo: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
