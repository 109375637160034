import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Entity } from '../models/entity';
import { Season } from '../models/season';
import { Team } from '../models/team';
import { User } from '../models/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertMessageComponent } from '../components/alert-message/alert-message/alert-message.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingModalComponent } from '../components/loading-modal/loading-modal.component';
import { MixpanelService } from '../mixpanel/mixpanel.service';
// import { TranslateService } from '@ngx-translate/core';

export const TOKEN_KEY = 'my-token';
export const TOKEN_USER = 'current-user';
export const TOKEN_USER_ID = 'current-user-id';
export const TOKEN_EMAIL = 'email-user';
export const TOKEN_PASS = 'password-user';
export const TOKEN_HEADER_TEAM = 'header-team';
export const TOKEN_HEADER_SEASON = 'header-season';
export const TOKEN_HEADER_ENTITY = 'header-entity';
export const TOKEN_DISTANCE = 'header-distance';


@Injectable({ providedIn: 'root' })
export class Core{

    private header = new Subject<any>();
    private headerChanges = new Subject<any>();
    private headerTeam = new Subject<any>();
    private headerTeamInfo = new Team();
    private headerSeason = new Subject<any>();
    private headerSeasonInfo = new Season();
    private headerEntity = new Subject<any>();
    private headerEntityInfo = new Entity();
    private user = new Subject<User>();
    private userInfo = new User();
    private isAuthenticated :Subject<boolean> = new BehaviorSubject<boolean>(false);
    private menuActive = new Subject<any>();
    private loadingModal: any;
    private headerDistance = new Subject<any>();
    private headerDistanceInfo = '';

    private scrollSubject = new Subject<any>()

    private menuCollapsedInfo = false;
    private menuCollapsed = new Subject<any>()

    private stickyButtonVisibleSubject = new BehaviorSubject<boolean>(false);
    stickyButtonVisible$ = this.stickyButtonVisibleSubject.asObservable();
    
    constructor(
        private router: Router,
        private snackbar: MatSnackBar,
        private matDialog: MatDialog,
        private mixpanelService: MixpanelService
        // private translateService: TranslateService
    ) { }

    /*
        user
    */
    getUser(): Observable<any> {
        return this.user.asObservable();
    }

    setUser(state: any) {
        this.setUserInfo(state);
        return this.user.next(state);
    }

    /*
        userInfo
    */
    getUserInfo(): User {
        return this.userInfo;
    }

    setUserInfo(user: User){
        environment.userId = localStorage.getItem(TOKEN_USER_ID) as string;
        // environment.user = JSON.parse(localStorage.getItem(TOKEN_USER) as string);
        localStorage.setItem(TOKEN_USER,JSON.stringify(user));
        // console.log(user.lang!)
        // this.translateService.use('es')

        environment.userToken = localStorage.getItem(TOKEN_KEY) as string;
        this.userInfo = user;
    }

    /*
        isAuthenticated
    */
    getIsAuthenticated(): Observable<boolean> {
        return this.isAuthenticated.asObservable();
    }

    setIsAuthenticated(state: any) {
        return this.isAuthenticated.next(state);
    }

    /*
        header
    */
    getHeader(): Observable<any> {
        return this.header.asObservable();
    }

    setHeader(state: any) {
        return this.header.next(state);
    }
    

    /*
        header
    */
    getHeaderChanges(): Observable<any> {
        return this.headerChanges.asObservable();
    }

    setHeaderChanges(state: any) {
        return this.headerChanges.next(state);
    }
    

    /*
        team
    */
    getHeaderTeam(): Observable<any> {
        return this.headerTeam.asObservable();
    }

    setHeaderTeam(team: any) {
        this.setHeaderTeamInfo(team);
        return this.headerTeam.next(team);
    }

    getHeaderTeamInfo(): Team {
        return this.headerTeamInfo;
    }

    setHeaderTeamInfo(team: Team) {
        this.headerTeamInfo = team;
        localStorage.setItem(TOKEN_HEADER_TEAM, JSON.stringify(team));
        this.setHeaderChanges(team);
    }
    
    /*
        season
    */
    getHeaderSeason(): Observable<any> {
        return this.headerSeason.asObservable();
    }

    setHeaderSeason(state: any) {
        this.setHeaderSeasonInfo(state);
        return this.headerSeason.next(state);
    }
        
    getHeaderSeasonInfo(): Season {
        return this.headerSeasonInfo;
    }

    setHeaderSeasonInfo(season: Season) {
        this.headerSeasonInfo = season;
        localStorage.setItem(TOKEN_HEADER_SEASON, JSON.stringify(season));
        this.setHeaderChanges(season);
    }
    
    /*
        entity
    */
    getHeaderEntity(): Observable<any> {
        return this.headerEntity.asObservable();
    }

    setHeaderEntity(state: any) {
        this.setHeaderEntityInfo(state);
        return this.headerEntity.next(state);
    }
        
    getHeaderEntityInfo(): Entity {
        return this.headerEntityInfo;
    }

    setHeaderEntityInfo(entity: Entity) {
        this.headerEntityInfo = entity;
        this.getColorEntity(entity);
        localStorage.setItem(TOKEN_HEADER_ENTITY, JSON.stringify(entity));
    }
    


    getMenuActive(): Observable<any> {
        return this.menuActive.asObservable();
    }

    setMenuActive(state: string) {
        return this.menuActive.next(state);
    }
    
    /*
        canLoadModules
    */
    canLoadModules(actions: string[]): boolean {
        let v=false;

        if(!actions || actions.length==0){return true;}

        let modules = this.userInfo.modules;

        if(modules){
            modules.forEach(module => {
                if(actions)
                actions.forEach(action => {
                    if (module.module?.toLowerCase() == action && module.active) {
                        if(!v){v = true}
                        else{v = v && true;}
                    }
                });
            })
        }

        return v;
    }

    /*
        canActivateRoles
    */
    canActivateRoles(actions: number[]): boolean {
        let v = false;

        if(!actions || actions.length==0){return true;}

        actions.forEach(role=>{
            if(role == this.userInfo.role){v = true;}
        })

        return v;
    }

    /*
        logOut
    */
    logOut(){
        this.setHeader({backButton: '', title:'', filters: false})
        this.setIsAuthenticated(false);
        this.setUser({})
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(TOKEN_USER);
        localStorage.removeItem(TOKEN_USER_ID);
        localStorage.removeItem(TOKEN_HEADER_TEAM);
        localStorage.removeItem(TOKEN_HEADER_SEASON);
        localStorage.removeItem(TOKEN_HEADER_ENTITY);
        this.router.navigateByUrl('/login')
    }


    getColorEntity(entity:any){
        (<HTMLElement>document.querySelector(':root')).style.setProperty('--primary', entity.primary_color);
        let primaryBg = entity.primary_color + '22';
        (<HTMLElement>document.querySelector(':root')).style.setProperty('--primary_bg', primaryBg);
        (<HTMLElement>document.querySelector(':root')).style.setProperty('--secondary', entity.secondary_color);
        let secondaryBg = entity.secondary_color + '50';
        (<HTMLElement>document.querySelector(':root')).style.setProperty('--secondary_bg', secondaryBg);
        (<HTMLElement>document.querySelector(':root')).style.setProperty('--tertiary', entity.tertiary_color);
        let tertiaryBg = entity.tertiary_color + '22';
        (<HTMLElement>document.querySelector(':root')).style.setProperty('--tertiary_bg', tertiaryBg);
        let logo = 'url(' + entity.logo + ')';
        (<HTMLElement>document.querySelector(':root')).style.setProperty('--logo', logo);
    }

    /**
     * Mostramos el alert de abajo.
     * 
     * @param message 
     * @param type 
     */
    showMessage(message: string, type: string) {
        this.snackbar.openFromComponent(AlertMessageComponent, {
        data: {
            message: message,
            type: type
        },
        panelClass: ['snack-' + type],
        duration: 5000,
        verticalPosition: 'top'
        });
    }

    openLoading(text: string){
        this.loadingModal = this.matDialog.open(LoadingModalComponent, {
            data: {'text': text},
            backdropClass: 'backdrop-dialog',
            panelClass: 'shared-dialog',
            disableClose: true
        });
    }

    closeLoading(){
        this.loadingModal.close()
    }

    changeUserDistance(distance: string){
        let user = this.userInfo
        user.distance = distance;
        localStorage.setItem(TOKEN_USER, JSON.stringify(user));
        this.setUser(user)
    }


    getMenuCollapsed(): Observable<any> {
        return this.menuCollapsed.asObservable();
    }
    
    getMenuCollapsedInfo(): boolean{
        return this.menuCollapsedInfo
    }

    setMenuCollapsed(state: boolean) {
        return this.menuCollapsed.next(state);
    }
    
    /*
    distance
    */
    getHeaderDistance(): Observable<any> {
        return this.headerDistance.asObservable();
    }

    setHeaderDistance(state: any) {
        this.setHeaderDistanceInfo(state);
        return this.headerDistance.next(state);
    }
        
    getHeaderDistanceInfo(): string {
        return this.headerDistanceInfo;
    }

    setHeaderDistanceInfo(distance: string) {
        this.headerDistanceInfo = distance;
        localStorage.setItem(TOKEN_DISTANCE, distance);
        this.changeUserDistance(distance)
    }


    getScrollSubject(): Observable<any> {
        return this.scrollSubject.asObservable();
    }

    setScrollSubject(state: any) {
        return this.scrollSubject.next(state);
    }

    setStickyButtonVisible(visible: boolean) {
        this.stickyButtonVisibleSubject.next(visible);
    }
}
