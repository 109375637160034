import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private languageSource = new BehaviorSubject<string>('en');
  currentLanguage = this.languageSource.asObservable();

  constructor(private translateService: TranslateService) {
    // Inicializar el servicio de traducción con el idioma por defecto
    this.translateService.use(this.languageSource.value);
  }

  changeLanguage(language: string) {
    this.languageSource.next(language);
    this.translateService.use(language); // Cambiar el idioma en TranslateService
  }

  getCurrentLanguage(): string {
    return this.languageSource.value;
  }
}