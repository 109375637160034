import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'intToDate'
})
export class IntToDatePipe implements PipeTransform {

  transform(value: any, args: any): any {
    if(value == 0){
      return 0;
    }
    let newDate;
    newDate = format(new Date(value * 1000), args)
    return newDate;
  }

}
