import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { TranslateService } from '@ngx-translate/core';

export const TOKEN_KEY = 'my-token';
export const TOKEN_USER = 'current-user';
export const TOKEN_USER_ID = 'current-user-id';
export const TOKEN_EMAIL = 'email-user';
export const TOKEN_PASS = 'password-user';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  token = '';
  headers: any;

  constructor(private http: HttpClient,  private router: Router, private core: Core, private translateService: TranslateService) {
    this.loadToken();

    this.headers = new HttpHeaders();
    this.headers.append('Content-Type','application/json');
    this.headers.append("Access-Control-Allow-Origin", '*');
    this.headers.append('Accept', 'application/json');
  }

  loadToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      this.token = token;
      this.core.setIsAuthenticated(true);
    } else {
      this.core.setIsAuthenticated(false);
    }
  }

  login(email: any, password: any): Observable<any> {

    return this.http.post(environment.apiUrl + '/login', { email: email, password: password }, { headers: this.headers }).pipe(
      map((data: any) => {
        if(data.status == 404){
          return data
        }

        // Usuarios que solo pueden acceder a la app (MVP)
        if(data.data.only_app){
          data.status = 404;
          return data;
        }

        localStorage.setItem(TOKEN_KEY, data.data.jwt);
        localStorage.setItem(TOKEN_USER_ID, data.data.id);
        localStorage.setItem(TOKEN_USER, JSON.stringify(data.data));

        localStorage.setItem(TOKEN_EMAIL, email);
        localStorage.setItem(TOKEN_PASS, password);

        this.translateService.use(data.data.lang)

        environment.userId = data.data.id;
        environment.user = data.data;
        environment.userDistance = data.data.distance;
        environment.userToken = data.data.jwt;

        //this.core.setHeader({backButton: '', title:'dashboard', filters: true})
        this.core.setUser(data.data);
        this.core.setUserInfo(data.data);
        this.core.setIsAuthenticated(true);

        return data;
      }),
      // tap(_ => {
      //   console.log('je je je')
      //   this.core.setIsAuthenticated(true);
      // })
    )
    
  }
}
