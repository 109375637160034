import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { MixpanelBaseService } from '../mixpanel-base.service';
import { CompetitionInterface } from '../interface/mixpanel.interface'
import { Exercise } from '../../models/exercise';
import { Competition } from '../../models/competition';


@Injectable({
  providedIn: 'root',
})
export class MixpanelCompetitionService extends MixpanelBaseService implements CompetitionInterface {
    constructor() {
        super();
    }

    clickNewCompetition(): void{
        mixpanel.track('Click New Competition', {});
    }

    newCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void{
        let type = '';
        switch(competition.type){
            case 1: type = 'TOURNAMENT'; break;
            case 2: type = 'QUALIFIER'; break;
        }

        let livescoring = competition.livescoring ? 'TRUE' : 'FALSE'

        mixpanel.track('New Competition', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Competition type': type,
            'Competition type id': competition.type,
            'Competition start date': start_date,
            'Competition livescoring': livescoring, 
            'Competition golf course':course,
            'Competition tee': tee,
            'Competition scorecard': scorecard,
        });
    }

    newRound(competition: Competition, holes: number, date: string): void{
        mixpanel.track('New Competition Round', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Competition round holes': holes,
            'Competition round date': date,
        });
    }

    newTeeTime(competition: Competition, course: string, tee: string, scorecard: string, hasChangedCourse: boolean, startingHole: number): void{
        mixpanel.track('New Competition Tee Time', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Tee time course': course,
            'Tee time tee': tee,
            'Tee time scorecard': scorecard,
            'Course changed': hasChangedCourse,
            'Tee time starting hole': startingHole,
        });
    }

    clickCompetitionTab(tabName: string): void{
        mixpanel.track('Click Competition Tab', {
            'Tab name': tabName,
        });
    }
    
    putCompetitionHole(competition: Competition, hole: number, gross: number, par: number): void{
        mixpanel.track('Put Competition Hole', {
            'Competition Id': competition.id,
            'Competition name': competition.name,
            'Hole': hole,
            'Gross': gross,
            'Par': par
        })
    }


}
