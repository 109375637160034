import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { getLocaleDateFormat } from '@angular/common';
import { environment } from 'src/environments/environment';

export const TOKEN_USER = 'my-token';
export const USER_ID = 'current-user-id';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token;
        let id;

        token = localStorage.getItem(TOKEN_USER);
        id = localStorage.getItem(USER_ID);

        if(!id){id = 0;}
        
        //id = 136;
        //token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImhvcnRzQHRlY25vcXVhdHJlLmVzIiwiaWQiOjEzNiwibW9kdWxvcyI6W3sibW9kdWxlIjoiR0VORVJBTF9QUkFDVElDRSIsImlkIjoxLCJhY3RpdmUiOnRydWV9LHsibW9kdWxlIjoiV09SS19PVVRTIiwiaWQiOjIsImFjdGl2ZSI6dHJ1ZX0seyJtb2R1bGUiOiJDT01NVU5JQ0FUSU9OUyIsImlkIjozLCJhY3RpdmUiOnRydWV9LHsibW9kdWxlIjoiQ09NUEVUSVRJT05TIiwiaWQiOjQsImFjdGl2ZSI6dHJ1ZX0seyJtb2R1bGUiOiJUUklQUyIsImlkIjo1LCJhY3RpdmUiOnRydWV9LHsibW9kdWxlIjoiUkVDUlVJVE1FTlQiLCJpZCI6NiwiYWN0aXZlIjp0cnVlfSx7Im1vZHVsZSI6IldBUkVIT1VTRSIsImlkIjo3LCJhY3RpdmUiOnRydWV9LHsibW9kdWxlIjoiU1RBVFMiLCJpZCI6OCwiYWN0aXZlIjp0cnVlfSx7Im1vZHVsZSI6IkJVREdFVCIsImlkIjo5LCJhY3RpdmUiOnRydWV9LHsibW9kdWxlIjoiU0NIT1JMQVNISVBfQVJSQU5HRU1FTlQiLCJpZCI6MTAsImFjdGl2ZSI6dHJ1ZX1dLCJyb2xlIjoxLCJmcmVlbGFuY2UiOmZhbHNlfQ.vpa6CX53Gx1cffHDbAnYw8GgcWpqE8anksXMtHfnT7w"
        if (token) {
           
            request = request.clone({
                setHeaders: {
                    Accept: `application/json`,
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${token}`,
                    'user-z-id': id.toString(),
                    'header-api-key': 'x',
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Accept: 'application/json'
                }
            });
        }
        
        return next.handle(request);
        
    }
    
}