import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './public/pages/login/login.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { AuthGuard } from './shared/guards/auth/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import {HttpClientModule, HttpClient} from '@angular/common/http';

import { MixpanelService } from './shared/mixpanel/mixpanel.service';
import { CompetitionTrackingToken, GeneralEventsTrackingToken, GeneralPracticeTrackingToken, SignUpTrackingToken, StatsTrackingToken } from './shared/mixpanel/interface/mixpanel.interface';
import { MixpanelGeneralEventsTrackingService } from './shared/mixpanel/general-service/mixpanel-general-events.service';
import { MixpanelSignUpService } from './shared/mixpanel/account-and-user-service/mixpanel-sign-up.service';
import { MixpanelStatsService } from './shared/mixpanel/stats-service/mixpanel-stats.service';
import { MixpanelGeneralPracticeService } from './shared/mixpanel/general-practice-service/general-practice.service';
import { MixpanelCompetitionService } from './shared/mixpanel/competition-service/competition.service';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { translatePartialLoader } from './shared/config/translation.config';
import { MultiTranslateHttpLoader } from './shared/translate/translate-custom-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
/*variable global socket*/
const config: SocketIoConfig = {
  url: 'https://socket.coachinbounds.com:3000?t='+Date.now(),
  //url: 'localhost:3000',
  options: {
    // cors: { origin: '*' },
    autoConnect: false,
    reconnection: true
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    LoginModule,
    BrowserAnimationsModule,
    SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          // useFactory: createTranslateLoader,
          useFactory: (http: HttpClient): MultiTranslateHttpLoader => {
            return new MultiTranslateHttpLoader(http, {
              withCommon: false,
              resources: [
                { prefix: './assets/i18n/common/', suffix: '.json' },
                { prefix: './assets/i18n/login/', suffix: '.json' },
                { prefix: './assets/i18n/menu/', suffix: '.json' },
                { prefix: './assets/i18n/breadcrumbs/', suffix: '.json' },
                { prefix: './assets/i18n/tabs/', suffix: '.json' },
                { prefix: './assets/i18n/dashboard/', suffix: '.json' },
                { prefix: './assets/i18n/players/', suffix: '.json' },
                { prefix: './assets/i18n/stats/', suffix: '.json' },
                { prefix: './assets/i18n/competitions/', suffix: '.json' },
                { prefix: './assets/i18n/practices/', suffix: '.json' },
                { prefix: './assets/i18n/trips/', suffix: '.json' },
                { prefix: './assets/i18n/configuration/', suffix: '.json' },
                { prefix: './assets/i18n/modals/', suffix: '.json' },
                { prefix: './assets/i18n/tooltips/', suffix: '.json' },
              ],
            });
          },
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, 
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
    /**duracion del snackbar para toda la web */
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2000}},
    MixpanelService,
    {
      provide: GeneralEventsTrackingToken,
      useClass: MixpanelGeneralEventsTrackingService
    },
    {
      provide: SignUpTrackingToken,
      useClass: MixpanelSignUpService
    },
    {
      provide: StatsTrackingToken,
      useClass: MixpanelStatsService
    },
    {
      provide: GeneralPracticeTrackingToken,
      useClass: MixpanelGeneralPracticeService
    },
    {
      provide: CompetitionTrackingToken,
      useClass: MixpanelCompetitionService
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
