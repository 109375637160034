export const en: { [key: string]: string } = {
    'GENERAL_PRACTICE':'General practice',
    'WORK_OUTS':'Workouts',
    'COMMUNICATIONS':'Communications',
    'COMPETITIONS':'Competitions',
    'TRIPS':'Trips',
    'RECRUITMENT':'Recruitment',
    'WAREHOUSE':'Equipment',
    'STATS':'Stats',
    'BUDGET':'Budget',
    'SCHOLARSHIP_ARRANGEMENT':'Scholarship arrangement',
    'New season':'New season',
    'Name':'Name',
    'Start date':'Start date',
    'End date':'End date',
    'Cancel':'Cancel',
    'Accept':'Accept',
    'save':'save',
    'Edit season':'Edit season',
    'New team':'New team',
    'Institution':'Institution',
    'Head coach':'Head coach',
    'Photo':'Photo',
    'Players':'Players',
    'Coaches':'Coaches',
    'New Trackman Url':'New Tech & Reports Url',
    'Trackman': 'Tech & Reports',
    'Received by': 'Received by',
    'Yes': 'Yes',
    'No': 'No',
    'CredentialError': 'Incorrect email or password',
    'Date': 'Date',
    'Team': 'Team',
    'Type': 'Tipo',
    // Stats
    'No competitions are available for the selected filters.':'No competitions are available for the selected filters.',
    // Dashboard - Livescoring
    'Pos.':'Pos.',
    'Hole':'Hole',
    'Today':'Today',
    'Total':'Total',
    'View competition':'View competition',
    // Dashboard - Calendar
    'Calendar':'Calendar',
    'List':'List',
    'Tournament':'Tournament',
    'Qualifier':'Qualifier',
    'Golf practice':'Golf practice',
    'Workout':'Workout',
    'Trip':'Trip',
    'There are no events for that day':'There are no events for that day',
    // General practice
    'Time': 'Time',
    'Duration': 'Duration',
    'Minutes': 'Minutes',
    'Create new plan': 'Create new plan',
    'Select players': 'Select players',
    'Exercises': 'Exercises',
    'Generate other plan': 'Generate other plan',
    'Add exercises': 'Add exercises',
    'Random': 'Random',
    'Custom': 'Custom',
    'Session': 'Session',
    'Max file size 100MB': 'Max file size 100MB',
    'To pass the test': 'to pass the test',
    'Edit': 'Edit',
    'Clone': 'Clone',
    'Delete': 'Delete',
    'New exercise': 'New exercise',
    // Tooltips
    'GIR': 'A statistic that measures the percentage of holes where the golfer hits the green in the prescribed number of strokes. Specifically, it means reaching the green in two strokes less than par (e.g., in two strokes on a par-4 hole or in three strokes on a par-5 hole).',
    "Scoring from fairway": "Tracks all tee shots that land in the fairway and records the number of birdies, pars, bogeys, and other scores made from those positions.",
    "Scoring from missing fairway": "Tracks all tee shots that miss the fairway and records the number of birdies, pars, bogeys, and other scores made from those positions.",
    "Misses from the tee": "Tracks all tee shots that miss the fairway and records where the player misses, including rough, bunker, penalty area, or out of bounds.",
    "Penalties from the tee": "Tracks all penalties incurred from tee shots, including shots that land in penalty areas, out of bounds.",
    "Fringe miss": "A statistic that records when a golfer's approach shot lands on the fringe, the area of closely mowed grass that borders the putting green. This indicates a near miss of the green in regulation.",
    "Rough miss": "A statistic that records when a golfer's shot lands in the rough, the area of longer grass surrounding the fairway and green. This indicates a miss of the green.",
    "Bunker miss": "A statistic that records when a golfer's shot lands in a bunker (sand trap) and does not result in a Green in Regulation (GIR). This indicates that the golfer did not reach the green in the prescribed number of strokes.",
    "Other miss": "A statistic that records when a golfer's shot lands in any area other than the fairway, rough, bunker, or green. This includes hazards, penalty areas, and out of bounds, indicating an errant shot.",
    "GIR accuracy from <95": "A statistic that measures the percentage of times a golfer hits the green in regulation (GIR) from distances of 95 yards or less. This indicates the golfer's accuracy and precision with approach shots from short distances.",
    "GIR accuracy from <155": "A statistic that measures the percentage of times a golfer hits the green in regulation (GIR) from distances between 96 and 155 yards. This indicates the golfer's accuracy and precision with approach shots from mid-range distances.",
    "GIR accuracy from >155": "A statistic that measures the percentage of times a golfer hits the green in regulation (GIR) from distances over 155 yards. This indicates the golfer's accuracy and precision with approach shots from long distances.",
    "Proximity next shot from": "Measures the average distance of the first putt when a golfer attempts to get up and down from around the green. This indicates the golfer's proximity to the hole on their first putt after chipping or pitching.",
    "Number of 3 putts": "Measures the number of times a golfer takes three putts to hole out, expressed as a percentage of total holes played.",
    "Putt made from": "Measures the percentage of putts made from various distances. This statistic shows your success rate in converting putts from specific ranges, indicating your proficiency and accuracy on the greens.",
    "Putt scoring": "Tracks the number of putts made for eagle, birdie, par, and other scores from various distances. This statistic shows your success rate in achieving different scores based on the distance of the putt."
}