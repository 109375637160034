import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  host: {'class': 'dialog-content'}
})
export class ConfirmComponent implements OnInit {

  // Traducciones para los botones
  cancelButtonText: string = '';
  confirmButtonText: string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>, // Asegúrate de que el tipo sea ConfirmComponent
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string, 
      text: string, 
      buttons: string[], 
      cancel: string, 
      confirm: string
    }
  ) { }

  ngOnInit(): void {
    // Mapear los textos de los botones traducidos
    this.cancelButtonText = this.data.cancel;
    this.confirmButtonText = this.data.confirm;
  }

  /**
   * Cerramos el diálogo con true.
   */
  confirm() {
    this.dialogRef.close(true);
  }

  /**
   * Cerramos el diálogo con false.
   */
  closeDialog() {
    this.dialogRef.close(false);
  }

  /**
   * Maneja el clic en los botones del diálogo.
   */
  handleButtonClick(buttonText: string) {
    console.log(buttonText, this.cancelButtonText);
    
    if (buttonText === this.cancelButtonText) {
      
      this.closeDialog();
    } else if (buttonText === this.confirmButtonText) {
      this.confirm();
    }
  }
}
