import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDigits'
})
export class TwoDigitsPipe implements PipeTransform {

  transform(value: any): any {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

}
