import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import moment from 'moment';
import 'moment/locale/es'; // importar el idioma español
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class MixpanelBaseService {
  protected token = environment.mixpanelToken;

  protected initMixpanel(): void {
    mixpanel.init(this.token);
  }

  protected deleteQueryParamsFromURL(url: string): string {
    return url.split('?')[0];
  }

  protected formatDate(dateString: string): string {
    moment().locale('es');
    const currentDateTime = moment(dateString).format('ddd, DD MMM YYYY, HH:mm:ss');

    return currentDateTime;
  }

  protected toTitleCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}
