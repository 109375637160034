import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Core } from '../../core/core';
import { ChatService } from 'src/app/features/chat/services/chat.service';
import { Entity } from '../../models/entity';

export const TOKEN_COLLAPSED_MENU = 'collapsed-menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('menuAnimation', [
      state('expanded', style({
        width: '168px'
      })),
      state('collapsed', style({
        width: '48px'
      })),
      transition('expanded <=> collapsed', [
        animate('0.5s ease')
      ])
    ]),
    trigger('textAnimation', [
      state('visible', style({ width: '*'})),
      state('hidden', style({ width: '0px' })),
      transition('visible => hidden', [animate('500ms ease-in-out')]),
      transition('hidden => visible', [animate('500ms ease-in-out')]),
    ]),
  ]
})
export class MenuComponent implements OnInit {

  hasNewMessages: boolean = false;
  menuIsCollapsed: boolean = false;
  teamIsCollapsed: boolean = false;
  practicesIsCollapsed: boolean = false;
  activeMenuItem = '';
  entityImage = '';
  entity: Entity;
  user: any;

  innerWidth: any
  innerHeight: any
  isTablet: boolean = false

  constructor(
    public core: Core,
    public chatService: ChatService
  ) {
    this.entityImage = this.core.getHeaderEntityInfo().logo!
    this.entity = this.core.getHeaderEntityInfo()
    console.log(    this.user = this.core.getUserInfo().role);
  

    this.core.getHeaderEntity().subscribe(res=>{
      this.entity = res
    })

    // Menu tablet
    this.innerWidth = window.innerWidth
    this.innerHeight = window.innerHeight

    if(this.getIsTablet()){
      this.isTablet = true
      this.menuIsCollapsed = true
      this.core.setMenuCollapsed(true)
    }

    // Localstorage para cargar configuracion anterior
    let collapsedJson = JSON.parse(localStorage.getItem(TOKEN_COLLAPSED_MENU) as string)
    if(collapsedJson && !this.isTablet){
      this.menuIsCollapsed = collapsedJson.collapsed
    }
    
  }

  ngOnInit(): void {
    this.pendingMessages();
    this.receiveMessage();
    this.loadActiveMenuItem();
    this.loadEntityImage()
    if(this.innerWidth < 1366){
      this.isTablet = true
      this.menuIsCollapsed = true
    }
  }

  logout() {
    this.core.logOut();
  }

  canAccess(modules: string[], roles: number[]): boolean {
    let role = this.core.canActivateRoles(roles);
    let module = this.core.canLoadModules(modules);
    // Quitado Workouts
    if(modules[0] == 'work_outs'){return false;}

    if (role && module) { return true; }
    else { return false };
  }

  /**
   * Comprobar si tengo mensajes nuevos
   */
  pendingMessages() {
    this.chatService.pendingMessages().subscribe(res => {
      this.hasNewMessages = res['data'].has_pending_messages;
    });
  }

  /**
   * Saber si recibo algun mensaje
   */
  receiveMessage() {
    this.chatService.receiveMessage_from().subscribe(res => {
      // if (this.core.getMenuActive() != 'chat') {
      //   this.hasNewMessages = true;
      // } 
    });
  }

  loadActiveMenuItem(){
    this.core.getMenuActive().subscribe(res=>{
      this.activeMenuItem = res
    })
  }

  setActiveMenuItem(state: string){
    if(this.getIsTablet()){
      this.menuIsCollapsed = true;
    }
    this.core.setMenuActive(state)
  }

  loadEntityImage(){
    this.core.getHeaderEntity().subscribe(res=>{
      this.entityImage = res.logo
    })
  }

  collapseMenu(){
    this.menuIsCollapsed = !this.menuIsCollapsed

    console.log(this.menuIsCollapsed);

    this.core.setMenuCollapsed(this.menuIsCollapsed)
    

    if(!this.getIsTablet()){
      let json = {'collapsed': this.menuIsCollapsed}
      let menuStringify = JSON.stringify(json);
      localStorage.setItem(TOKEN_COLLAPSED_MENU, menuStringify);  
    }
  }

  getIsTablet(){
    this.innerWidth = window.innerWidth
    this.innerHeight = window.innerHeight

    if(this.innerWidth < 1366){
      return true
    }
    return false;
  }

  getTextState() {
    return this.menuIsCollapsed ? 'hidden' : 'visible';
  }

  getRouterLinkForPlayers(): string {
    const userInfo = this.core.getUserInfo();
    
    if (userInfo.role == 3) {
      return `/players/${userInfo.id}`;
      
    } else {
      return '/players';
    }
  }

  getRouterLinkForStats(): string[] {
    const userInfo = this.core.getUserInfo();
    if (userInfo.role == 3) {
      return ['/stats', userInfo.id.toString()];
    } else {
      return ['/stats'];
    }
  }
}