import { Injectable } from '@angular/core';
import { TranslateSet } from '../../models/translateSet';
import { environment } from 'src/environments/environment';
import { en } from '../../../../assets/i18n/en';
import { es } from '../../../../assets/i18n/es';
import { Core } from '../../core/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(
    private core: Core
  ) {}

  /**variables idiomas */
  public languages = ['es', 'en'];
  public esValues = es;
  public enValues = en;
  
  /**variable para el usuario guardado en local */
  public currentUser:any;
  

  /**construimos el diccionario de pares clave valor */
  private dictionary: { [key: string]: TranslateSet } = {
    es: {
      language: 'es',
      values: this.esValues,
    },
    en: {
      language: 'en',
      values: this.enValues,
    },
  }

  /**metodo service para mirar la palabra que queremos traducir en el diccionario en su idioma */
  translate(key: string): any {
    this.currentUser = this.core.getUserInfo();
    if(this.currentUser){
      let language = this.currentUser['lang'];
      if (this.dictionary[language] != null) {
        return this.dictionary[language].values[key];
      }
      else{
        return this.dictionary['en'].values[key];
      }
    }
    else{
      return this.dictionary['en'].values[key];
    }
  }
}
