import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Core } from 'src/app/shared/core/core';
import { LocationService } from 'src/app/shared/services/location/location.service';

@Component({
  selector: 'app-shared-modal',
  templateUrl: './shared-modal.component.html',
  styleUrls: ['./shared-modal.component.scss']
})
export class SharedModalComponent implements OnInit {

  type: string = ''
  title: string = '';
  text: string = '';
  selectedButton?: string = '';
  textButtons?: any[any] = [];

  constructor(
    public core: Core,
    public dialogRef: MatDialogRef<SharedModalComponent>,
    public dialog: MatDialog,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, text: string, type: string, selectedButton?: string, textButtons?: []}
  ) { 
    this.type = data.type
    this.title = data.title
    this.text = data.text
    this.selectedButton = data.selectedButton
    this.textButtons = data.textButtons
  }



  // data = {
  //   'title': '', 
  //   'text': 'Do you wish to clone “3 WAY APPROACH (15YDS) (MAX ACC. PERMISSION 5 FT AWAY FROM THE TEE”?', 
  //   'selectedButton': 'YES',
  //   'type': 'CONFIRM'
  // }

  ngOnInit(): void {
  }

  close(value: any){
    this.dialogRef.close(value)
  }

}
