export class Season{
    id: number;
    start_date!: number;
    end_date!: number;
    active?: boolean;
    name!: string;
    is_old?: boolean;

    constructor(){
        this.id = 0;
    }
}
