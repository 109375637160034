import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { MixpanelBaseService } from '../mixpanel-base.service';
import { GeneralPracticeInterface } from '../interface/mixpanel.interface'
import { Exercise } from '../../models/exercise';


@Injectable({
  providedIn: 'root',
})
export class MixpanelGeneralPracticeService extends MixpanelBaseService implements GeneralPracticeInterface {
    constructor() {
        super();
    }

    clickNewExercise(): void{
        mixpanel.track('Click New exercise', {});
    }

    clickSessionResults(): void{
        mixpanel.track('Click Session Results', {});
    }

    newExercise(exercise: Exercise): void {
        this.initMixpanel();

        mixpanel.track('New exercise', {
            'Exercise name': exercise.name,
            'Exercise id': exercise.id,
            'Exercise duration': exercise.time, 

            'Entity id': exercise.entity_id,

            'Area id': exercise.area.id,
            'Area name': exercise.area.name,

            'Exercise type': exercise.type, 
            'Exercise test type': exercise.test_type,
            
            'Exercise attempts required': exercise.attempts_required,
            'Exercise attempts max': exercise.attempts_max,

            'Exercise score required': exercise.score_required,
            'Exercise score min': exercise.score_min,
            'Exercise score max': exercise.score_max,
        });
      
    }

    editExercise(exercise: Exercise): void {
        this.initMixpanel();

        mixpanel.track('Edit exercise', {
            'Exercise name': exercise.name,
            'Exercise id': exercise.id,
            'Exercise duration': exercise.time, 

            'Entity id': exercise.entity_id,

            'Area id': exercise.area.id,
            'Area name': exercise.area.name,

            'Exercise type': exercise.type, 
            'Exercise test type': exercise.test_type,
            
            'Exercise attempts required': exercise.attempts_required,
            'Exercise attempts max': exercise.attempts_max,

            'Exercise score required': exercise.score_required,
            'Exercise score min': exercise.score_min,
            'Exercise score max': exercise.score_max,
        });
    }

    deleteExercise(exercise: Exercise): void {
        this.initMixpanel();

        mixpanel.track('Delete exercise', {
            'Exercise name': exercise.name,
            'Exercise id': exercise.id,
            'Exercise duration': exercise.time, 

            'Entity id': exercise.entity_id,

            'Area id': exercise.area.id,
            'Area name': exercise.area.name,

            'Exercise type': exercise.type, 
            'Exercise test type': exercise.test_type,
            
            'Exercise attempts required': exercise.attempts_required,
            'Exercise attempts max': exercise.attempts_max,

            'Exercise score required': exercise.score_required,
            'Exercise score min': exercise.score_min,
            'Exercise score max': exercise.score_max,
        });
    }

    cloneExercise(exercise: Exercise): void {
        this.initMixpanel();

        mixpanel.track('Clone exercise', {
            'Exercise name': exercise.name,
            'Exercise id': exercise.id,
            'Exercise duration': exercise.time, 

            'Entity id': exercise.entity_id,

            'Area id': exercise.area.id,
            'Area name': exercise.area.name,

            'Exercise type': exercise.type, 
            'Exercise test type': exercise.test_type,
            
            'Exercise attempts required': exercise.attempts_required,
            'Exercise attempts max': exercise.attempts_max,

            'Exercise score required': exercise.score_required,
            'Exercise score min': exercise.score_min,
            'Exercise score max': exercise.score_max,
        });
    }

}
