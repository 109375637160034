import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../services/translate/translate.service';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) { }

  /**llamada a service para traducir*/
  transform(value: any): string {
    // console.log(value, ":", this.TranslateService.translate(value))
    return this.translateService.translate(value) || value
  }
}