import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class StatsProgressBarComponent implements OnInit {

  @Input() progress: number = 0;
  percentage: number = 0;
  
  ngOnInit(): void {
    setTimeout(() => {
      this.percentage = this.progress
    }, 0);
  }


  ngOnChanges(changes: any){
    const isFirstChange = Object.values(changes).some((c:any)=> c.isFirstChange());
    if(!isFirstChange){
      this.percentage = 0;

      setTimeout(() => {
        this.percentage = this.progress
      }, 0);
    }
  }
}
