import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';
import { Entity } from '../../models/entity';
import { Stage } from '../../models/stage';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(
    private http: HttpClient,
    private core: Core
  ) { }

  /**
  * Carga la entidad.
  * 
  * @param entity id de la entidad a cargar
  * @returns Json
  */
  getEntity(entity: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/entities/' + entity);
  }

  /**
  * Creamos una nueva entidad.
  * 
  * @param entity entidad a crear
  * @returns Json
  */
  postEntity(entity: Entity): Observable<any> {
    return this.http.post(environment.apiUrl + '/entities', {entity});
  }

  /**
  * Editamos la entidad dada.
  * 
  * @param entity entidad a editar
  * @returns Json
  */
  putEntity(entity: Entity): Observable<any> {
    return this.http.put(environment.apiUrl + '/entities/' + entity.id, {entity});
  }

  /**
  * Eliminamos la entidad dada.
  * 
  * @param entity id de la entidad a eliminar
  * @returns Json
  */
  deleteEntity(entity: number): Observable<any> {
    return this.http.delete(environment.apiUrl + '/entities/' + entity);
  }

  /**
  * Carga las entidades visibles para el usuario activo.
  * 
  * @returns Json
  */
  getEntities(): Observable<any> {
    return this.http.get(environment.apiUrl + '/entities');
  }

  /**
  * Actualiza un stage de una entidad dada.
  * 
  * @param entity id de la entidad
  * @param stage objeto stage
  * @returns Json
  */
  postStage(entity: number, stage: Stage): Observable<any> {
    return this.http.post(environment.apiUrl + '/entities/' + entity + '/stages', stage);
  }
}
