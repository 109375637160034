<div>
    <div *ngIf="data.type == 'CONFIRM'">
        <app-confirm-modal [title]="title" [text]="text" [selectedButton]="selectedButton" (closeEvent)="close($event)" [textButtons]="textButtons"></app-confirm-modal>
    </div>
    <div *ngIf="data.type == 'ERROR'">
        <app-error-modal [title]="title" [text]="text" (closeEvent)="close($event)"></app-error-modal>
    </div>
    <div *ngIf="data.type == 'SUCCESS'">
        <app-success-modal [title]="title" [text]="text" (closeEvent)="close($event)"></app-success-modal>
    </div>
</div>