import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Core } from 'src/app/shared/core/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private subject = new Subject<any>();
  auth: any;

  constructor(private http: HttpClient, private core: Core, private socket: Socket, private injector: Injector) {
    this.socket.on('connect', () => {
      console.log(`%csocket id: ${socket.ioSocket.id}`, 'color:green');
      this.subject.next(socket.ioSocket);
    });
    this.socket.on('error', (err: any) => { console.log('Error connecting to server', err); });
    this.socket.on('disconnect', () => { console.log('Disconnect from server'); });
    this.socket.on('reconnect', (number: any) => { console.log('Reconnected to server', number); });
    this.socket.on('reconnect_attempt', () => { console.log('Reconnect Attempt'); });
    this.socket.on('reconnecting', (number: any) => { console.log('Reconnecting to server', number); });
    this.socket.on('reconnect_error', (err: any) => { console.log('Reconnect Error', err); });
    this.socket.on('reconnect_failed', () => { console.log('Reconnect failed'); });
    this.socket.on('connect_error', () => { /*console.log('connect_error');*/ });
  }

  /**
   * se conecta al socket por una url que está en app.module.
   */
  connect_socket() {
    this.socket.connect();
  }

  /**
   * Desconecta el socket del servidor.
   */
  disconnect_socket() {
    this.socket.disconnect();
  }

  /**
   * Devuelve un observable que lanza un evento cada vez que se conecta
   */
  connect_from() {
    return this.subject.asObservable();
  }

  /**
   * cargar chats
   * 
   */
  load_chats(): Observable<any> {
    return this.http.get(environment.apiUrl + '/chats');
  }

  /**
   * cargar chat
   * 
   */
  load_chat(id: any): Observable<any> {
    return this.http.get(environment.apiUrl + '/chats/' + id);
  }

  /**
   * Evento para unirse a una única sala
   * 
   * @param chat Chat
   */
  entrarEnUnaSala_emit(chat: any) {
    this.socket.emit('enter-chat', chat);
  }

  /**
   * Envía el chat respuesta al crear un chat
   * 
   * @param chat Chat respuesta al crear uno
   */
  nuevoChatSolicitar_emit(chat: any) {
    this.socket.emit('new-chat', chat);
  }

  /**
   * Emito un evento con la lista de los chats crear y entrar en las salas
   * usando los chats ids.
   * 
   * @param chats Array[ Chat ]
   */
  entrarEnVariasSalas_emit(chats: any[any]) {
    this.socket.emit('enter-chats', chats);
  }

  /**
   * Escucha del evento de 'conectado'.
   * 
   * @returns Observable que no recibe datos
   */
  conexionRealizada_from(): Observable<any> {
    return this.socket.fromEvent('conexion-done').pipe(map((data: any) => data));
  }

  /**
   * Enviar mensaje.
   * 
   * @param mensaje creo y envío el modelo Mensaje idChat y texto
   * @returns 'OK'
   */
  sendMessage(id: any, message: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/chats/' + id, message);
  }

  /**
   * Emito un evento 'mensaje'.
   * 
   * @param message Mensaje
   */
  sendMessage_emit(message: any) {
    this.socket.emit('message', message);
  }

  /**
   * Escucha del evento de 'mensaje'.
   * 
   * @returns Observable al que hay que subscribirse para recibir el mensaje
   */
  receiveMessage_from(): Observable<any> {
    return this.socket.fromEvent('message').pipe(map((data) => { return <any>data; }));
  }

  /**
   * Crea un nuevo chat.
   * @param json objeto json con los datos del chat(user[],type,title)
   * @returns Observable al que hay que subscribirse para recibir el mensaje
   */
  create_chat(json: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/chats', json);
  }

  /**
   * Escucha del evento de 'mensaje'.
   * 
   * @returns Observable al que hay que subscribirse para recibir el mensaje
   */
  messageReceive_from(): Observable<any> {
    return this.socket.fromEvent('message').pipe(map((data) => { return <any>data; }));
  }

  /**
   * Actualiza la conexión.
   * @param chat el chat actual
   * @returns Observable al que hay que subscribirse para recibir el mensaje
   */
  update_last_connection(chat: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/chats/' + chat.id + '/update-connection', {});
  }

  /**
  * Respuesta cuando otro usuario solicita abrir un chat
  * 
  * @returns 
  */
  newChatAccept_from(): Observable<any> {
    return this.socket.fromEvent('new-chat').pipe(map((data) => { return <any>data; }));
  }

  /**
 * saber si tenfgo mensajes pendientes
 * 
 */
  pendingMessages(): Observable<any> {
    return this.http.get(environment.apiUrl + '/chats-pending-messages');
  }





}
