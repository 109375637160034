import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Core } from './shared/core/core';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { ChatService } from './features/chat/services/chat.service';
import { environment } from 'src/environments/environment';
import { User } from './shared/models/user';
import { Subscription } from 'rxjs';
import { EntityService } from './shared/services/entity/entity.service';
import { MixpanelService } from './shared/mixpanel/mixpanel.service';
import Hotjar from '@hotjar/browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './shared/services/language/language.service';

export const TOKEN_USER = 'current-user';
export const TOKEN_HEADER_TEAM = 'header-team';
export const TOKEN_HEADER_SEASON = 'header-season';
export const TOKEN_HEADER_ENTITY = 'header-entity';
export const TOKEN_DISTANCE = 'header-distance';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'zaax-web';
  /*Si hay user logueado */
  isLogged: boolean = false;

  /* Atributos del header */
  headerTitle: string = '';
  headerHaveFilters: boolean = false;
  headerBack: string = '';

  /* Usuario activo */
  user: User = new User();

  headerSubscribe: Subscription = new Subscription();

  constructor(
    private core: Core,
    private authenticationService: AuthenticationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private chatService: ChatService,
    private mixpanelService: MixpanelService,
    private translateService: TranslateService,
    private langService: LanguageService
  ) {
    Hotjar.init(environment.hotjarTrackingCode, environment.hotjarVersion);

    //saber si el user esta logueado
    this.core.getIsAuthenticated().subscribe((x) => {
      this.isLogged = x;
      if(this.isLogged){
        this.initSocket();

        let user = JSON.parse(localStorage.getItem(TOKEN_USER) as string)
        this.core.setUser(user);

        this.core.setHeaderDistance(user.distance)

        this.langService.changeLanguage(user.lang);
        this.translateService.use(user.lang)
        
        let header_team = JSON.parse(localStorage.getItem(TOKEN_HEADER_TEAM) as string)
        if(header_team){this.core.setHeaderTeam(header_team);}
  
        let header_season = JSON.parse(localStorage.getItem(TOKEN_HEADER_SEASON) as string)
        if(header_season){this.core.setHeaderSeason(header_season);}
  
        let header_entity = JSON.parse(localStorage.getItem(TOKEN_HEADER_ENTITY) as string)
        if(header_entity){this.core.setHeaderEntity(header_entity);}

        let header_distance = localStorage.getItem(TOKEN_DISTANCE) as string
        if(header_distance){this.core.setHeaderDistance(header_distance)}
        else{this.core.setHeaderDistance('yd')}

        
        // Track mixpanel account
        this.mixpanelService.signUp(user)

        let role = '';
        switch(this.core.getUserInfo().role){
          case 1: role = 'ADMIN'; break;
          case 2: role = 'COACH'; break;
          case 3: role = 'PLAYEr'; break;
        }

        Hotjar.identify(this.core.getUserInfo().id.toString(), {
          'role': role,
          'entity': this.core.getUserInfo().entity_fav.name
        })

      }
    });

    this.registryIcons();
  }

  initSocket() {
    if (this.isLogged) {
      this.chatService.disconnect_socket();

      this.chatService.connect_from().subscribe( () => {this.initConexion(); });
      this.chatService.connect_socket();

    }
    else{
      this.chatService.disconnect_socket();
    }
  }

  private initConexion() {
    this.chatService.load_chats().subscribe( res => {
      // this.initContadorDeNotificaciones(res);
      let usuario = {
        id: 'user-'+environment.userId,
        titulo: environment.user.name + ' ' + environment.user.surname
      }
      this.chatService.entrarEnUnaSala_emit(usuario);
      this.chatService.entrarEnVariasSalas_emit(res['data']);
    });

    this.chatService.newChatAccept_from().subscribe(res => {
      // console.log('entrar en la sala', res);
      this.chatService.entrarEnUnaSala_emit(res);
    });
  }

  registryIcons(){
    this.matIconRegistry.addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/home.svg"));
    this.matIconRegistry.addSvgIcon('users', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/users.svg"));
    this.matIconRegistry.addSvgIcon('user-plus', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/user-plus.svg"));
    this.matIconRegistry.addSvgIcon('practice', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/practice.svg"));
    this.matIconRegistry.addSvgIcon('stats', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/stats.svg"));
    this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/calendar.svg"));
    this.matIconRegistry.addSvgIcon('chat', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chat.svg"));
    this.matIconRegistry.addSvgIcon('recruitment', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/recruitment.svg"));
    this.matIconRegistry.addSvgIcon('warehouse', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/warehouse.svg"));
    this.matIconRegistry.addSvgIcon('configuration', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/configuration.svg"));
    this.matIconRegistry.addSvgIcon('eye-opened', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/eye-opened.svg"));
    this.matIconRegistry.addSvgIcon('eye-closed', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/eye-closed.svg"));
    this.matIconRegistry.addSvgIcon('alert', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/alert.svg"));
    this.matIconRegistry.addSvgIcon('danger', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/danger.svg"));
    this.matIconRegistry.addSvgIcon('check', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/check.svg"));
    this.matIconRegistry.addSvgIcon('check-circle', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/check-circle.svg"));
    this.matIconRegistry.addSvgIcon('more', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/more.svg"));
    this.matIconRegistry.addSvgIcon('search', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/search.svg"));
    this.matIconRegistry.addSvgIcon('cross', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/cross.svg"));
    this.matIconRegistry.addSvgIcon('chevron-down', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-down.svg"));
    this.matIconRegistry.addSvgIcon('chevron-up', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-up.svg"));
    this.matIconRegistry.addSvgIcon('chevron-left', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-left.svg"));
    this.matIconRegistry.addSvgIcon('plus', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/plus.svg"));
    this.matIconRegistry.addSvgIcon('play', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/play.svg"));
    this.matIconRegistry.addSvgIcon('chevron-right', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/chevron-right.svg"));
    this.matIconRegistry.addSvgIcon('arrow-left', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/arrow-left.svg"));
    this.matIconRegistry.addSvgIcon('arrow-right', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/arrow-right.svg"));
    this.matIconRegistry.addSvgIcon('clock', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/clock.svg"));
    this.matIconRegistry.addSvgIcon('activity', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/activity.svg"));
    this.matIconRegistry.addSvgIcon('location', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/location.svg"));
    this.matIconRegistry.addSvgIcon('file', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/file.svg"));
    this.matIconRegistry.addSvgIcon('file-text', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/file-text.svg"));
    this.matIconRegistry.addSvgIcon('trophy', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/trophy.svg"));
    this.matIconRegistry.addSvgIcon('lock', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/lock.svg"));
    this.matIconRegistry.addSvgIcon('unlock', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/unlock.svg"));
    this.matIconRegistry.addSvgIcon('send', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/send.svg"));
    this.matIconRegistry.addSvgIcon('trash', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/trash.svg"));
    this.matIconRegistry.addSvgIcon('clipboard', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/clipboard.svg"));
    this.matIconRegistry.addSvgIcon('upload', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/upload.svg"));
    this.matIconRegistry.addSvgIcon('pencil', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/pencil.svg"));
    this.matIconRegistry.addSvgIcon('droplet', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/droplet.svg"));
    this.matIconRegistry.addSvgIcon('shopping-bag', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/shopping-bag.svg"));
    this.matIconRegistry.addSvgIcon('tag', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/tag.svg"));
    this.matIconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/edit.svg"));
    this.matIconRegistry.addSvgIcon('layout', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/layout.svg"));
    this.matIconRegistry.addSvgIcon('fast', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/fast-forward.svg"));
    this.matIconRegistry.addSvgIcon('file', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/file-plus.svg"));
    this.matIconRegistry.addSvgIcon('refresh', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/refresh.svg"));
    this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/download.svg"));
  }
}
