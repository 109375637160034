import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Core } from '../../core/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: HttpClient,
    private core: Core
  ) { }

  /**
  * Cargamos los paises disponibles.
  * 
  * @returns Json
  */
  getCountries(): Observable<any> {
    return this.http.get(environment.apiUrl + '/countries');
  }

  /**
  * Cargamos los estados disponibles.
  * 
  * @param country id del país seleccionado
  * @returns Json
  */
  getStates(country: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/countries/' + country + '/states');
  }

  /**
  * Cargamos las ciudades disponibles.
  * 
  * @param country id del país seleccionado
  * @param state id del estado seleccionado
  * @returns Json
  */
  getCities(country: number, state: number): Observable<any> {
    return this.http.get(environment.apiUrl + '/countries/' + country + '/states/' + state + '/cities');
  }
}
