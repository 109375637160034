import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  host: {'class' : 'toggle-button-component'}
})
export class ToggleButtonComponent implements OnInit {

  @Input() option: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
