<div class="filter">

    <!--IDIOMA-->
    <div class="filter__item">
        <select class="cursor-pointer" (change)="changeLang($event)" [(ngModel)]="languageSelected">
            <option *ngFor="let language of languages" [value]="language">{{ language | findLanguageFromKey }}</option>
        </select>
        <img src="../../../../assets/icons/chevron-down.svg">
    </div>


    <!--ENTITY-->
    <div class="filter__item" *ngIf="entities.length > 0">
        <select class="cursor-pointer" [(ngModel)]="entitySelected" (ngModelChange)="selectEntity()">
            <option [ngValue]="entity" *ngFor="let entity of entities">{{entity.name}}</option>
        </select>
        <img src="../../../../assets/icons/chevron-down.svg">
    </div>

    <!--TEAM-->
    <div class="filter__item">
        <select class="cursor-pointer" [(ngModel)]="teamSelected" (ngModelChange)="selectTeam()">
            <option [ngValue]="team" *ngFor="let team of teams">{{team.name}}</option>
        </select>
        <img src="../../../../assets/icons/chevron-down.svg">
    </div>

    <!--SEASON-->
    <div class="filter__item">
        <select class="cursor-pointer" [(ngModel)]="seasonSelected" (ngModelChange)="selectSeason()">
            <option [ngValue]="season" *ngFor="let season of seasons">{{season.name}}</option>
        </select>
        <img src="../../../../assets/icons/chevron-down.svg">

    </div>

</div>
