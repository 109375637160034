import { ApplicationModule } from './applicationModule';

export class Entity{
    id!: number;
    old_id?: number;
    name!: string;
    alias!: string;
    entity_name!: string;
    country?: string;
    country_id!: number;
    state?: string;
    state_id!: number;
    city?: string;
    city_id?: number;
    creation_date?: number;
    primary_color!: string;
    secondary_color!: string;
    tertiary_color!: string;
    logo?: string;
    logo_alternative?: string;
    video_login?: string;
    photo_login?: string;
    active?: boolean;
    visible?: boolean;
    modules?: ApplicationModule[];

    constructor(){
        this.state = '';
        this.state_id = 0;
        this.country = '';
        this.country_id = 0;
        this.city = '';
        this.city_id = 0;

        this.primary_color = "";
        this.secondary_color = "";
        this.tertiary_color = "";
    }

}
