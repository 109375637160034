<div class="menu_container" [@menuAnimation]="menuIsCollapsed ? 'collapsed' : 'expanded'"
    [ngClass]="{ 'menu_container--collapsed': menuIsCollapsed, 'menu_container--tablet': isTablet }">
    <!-- LOGO -->
    <div class="menu_container__header" [ngClass]="{'menu_container__header--collapsed': menuIsCollapsed}" [ngStyle]="{'background' : entity && entity.primary_color && entity.secondary_color ? ('linear-gradient(180deg, ' + entity.primary_color + ' 0%, ' + entity.secondary_color + ' 100%)')  : '#a7a7a7'}">
        <img class="menu_container__header__logo" [src]="entityImage">
    </div>
  
    <!-- ITEMS -->
    <div class="menu_container__items" [ngClass]="{'menu_container__items--collapsed': menuIsCollapsed}">
      <div class="collapse_button cursor-pointer" (click)="collapseMenu()" [ngClass]="{'collapse_button--collapsed': menuIsCollapsed}">
        <img src="../../../../assets/icons/menu/hamburger.svg"/>
      </div>

      <div class="container_items">
        <div [routerLink]="['/dashboard']" (click)="setActiveMenuItem('home')" class="menu_item" [ngClass]="{'menu_item--selected' : activeMenuItem == 'home'}">
          <img src="../../../../assets/icons/menu/home.svg"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.home"></span>
        </div>
      </div>
  
      <div class="menu_item__separator">
        <div></div>
      </div>
  
      <div class="container_items">
        <div class="menu_item" (click)="teamIsCollapsed = !teamIsCollapsed" [ngClass]="{'menu_item--selected' : (activeMenuItem == 'players' || activeMenuItem == 'stats') && teamIsCollapsed}">
          <img src="../../../../assets/icons/menu/arrow-down.svg" *ngIf="!teamIsCollapsed"/>
          <img src="../../../../assets/icons/menu/arrow-right.svg" *ngIf="teamIsCollapsed"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.team"></span>
        </div>
  
        <div [routerLink]="getRouterLinkForPlayers()" (click)="setActiveMenuItem('players')" *ngIf="!teamIsCollapsed" class="menu_item menu_item--sub_item" [ngClass]="{'menu_item--selected' : activeMenuItem == 'players'}">
          <img src="../../../../assets/icons/menu/golfer.svg"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.players"></span>
        </div>
  
        <div [routerLink]="getRouterLinkForStats()" (click)="setActiveMenuItem('stats')" *ngIf="!teamIsCollapsed" class="menu_item menu_item--sub_item" [ngClass]="{'menu_item--selected' : activeMenuItem == 'stats'}">
          <img src="../../../../assets/icons/menu/stats.svg"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.teamStats"></span>
        </div>
      </div>
  
      <div class="menu_item__separator">
        <div></div>
      </div>
  
      <div class="container_items">
        <div [routerLink]="['/competitions']" (click)="setActiveMenuItem('competitions')" class="menu_item" [ngClass]="{'menu_item--selected' : activeMenuItem == 'competitions'}">
          <img src="../../../../assets/icons/menu/trophy.svg"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.competitions"></span>
        </div>
      </div>
  
      <div class="menu_item__separator">
        <div></div>
      </div>
  
      <div class="container_items">
        <div class="menu_item" (click)="practicesIsCollapsed = !practicesIsCollapsed" [ngClass]="{'menu_item--selected' : (activeMenuItem == 'sessions' || activeMenuItem == 'exercises') && practicesIsCollapsed}">
          <img src="../../../../assets/icons/menu/arrow-down.svg" *ngIf="!practicesIsCollapsed"/>
          <img src="../../../../assets/icons/menu/arrow-right.svg" *ngIf="practicesIsCollapsed"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.practices"></span>
        </div>
  
        <div [routerLink]="['/practices/sessions']" (click)="setActiveMenuItem('sessions')" *ngIf="!practicesIsCollapsed" class="menu_item menu_item--sub_item" [ngClass]="{'menu_item--selected' : activeMenuItem == 'sessions'}">
          <img src="../../../../assets/icons/menu/sessions.svg"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.sessions"></span>
        </div>
  
        <div [routerLink]="['/practices/exercises']" (click)="setActiveMenuItem('exercises')" *ngIf="!practicesIsCollapsed" class="menu_item menu_item--sub_item" [ngClass]="{'menu_item--selected' : activeMenuItem == 'exercises'}">
          <img src="../../../../assets/icons/menu/exercises.svg"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.exercises"></span>
        </div>
      </div>
  
      <div class="menu_item__separator">
        <div></div>
      </div>
  
      <div class="container_items" *ngIf="core.getUserInfo().role != 3">
        <div [routerLink]="['/trips']" (click)="setActiveMenuItem('trips')" class="menu_item" [ngClass]="{'menu_item--selected' : activeMenuItem == 'trips'}">
          <img src="../../../../assets/icons/menu/plane.svg"/>
          <span class="menu_item__text" [@textAnimation]="getTextState()" translate="app.menu.trips"></span>
        </div>
      </div>
  
      <div class="menu_item__footer">
        <img src="../../../../assets/images/inbounds_logo_black.png" />
      </div>
    </div>
  </div>
  
  <div class="menu_tablet"></div>
  <div class="tablet_blurry" *ngIf="!menuIsCollapsed"></div>