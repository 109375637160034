<div class="circle">
    <div class="mask half">
        <div class="fill progress_{{id}}"></div>
    </div>
    <div class="mask full progress_{{id}}_fill">
        <div class="fill progress_{{id}}"></div>
    </div>
    <div class="inside-circle">
        <span class="stat">{{ progress }}</span>
        <span>%</span>
    </div>
</div>