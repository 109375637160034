import { Inject, Injectable } from "@angular/core";
import { CompetitionInterface, CompetitionTrackingToken, GeneralEventsTrackingInterface, GeneralEventsTrackingToken, GeneralPracticeInterface, GeneralPracticeTrackingToken, MixpanelInterface, SignUpTrackingInterface, SignUpTrackingToken, StatsTrackingInterface, StatsTrackingToken } from "./interface/mixpanel.interface";
import { User } from "../models/user";
import { Exercise } from "../models/exercise";
import { Competition } from "../models/competition";


@Injectable()
export class MixpanelService implements MixpanelInterface{
  constructor(
    @Inject(GeneralEventsTrackingToken) private generalService: GeneralEventsTrackingInterface,
    @Inject(SignUpTrackingToken) private signUpService: SignUpTrackingInterface,
    @Inject(StatsTrackingToken) private statsService: StatsTrackingInterface,
    @Inject(GeneralPracticeTrackingToken) private generalPracticeService: GeneralPracticeInterface,
    @Inject(CompetitionTrackingToken) private competitionService: CompetitionInterface,
  ){}

  /** Sign Up Service */
  signUp(user: User): void {
    this.signUpService.signUp(user);
  }

  /** General Service */
  trackPageName(pageName: string, currentUrl: string): void {
    this.generalService.trackPageName(pageName, currentUrl);
  }


  /** Stats Service */
  trackStat(name: string, id: number, typeName: string, typeId:number, categoryName: string, categoryId: number, startDate: number, endDate: number): void {
    this.statsService.trackStat(name, id, typeName, typeId, categoryName, categoryId, startDate, endDate)
  }

  trackPlayerCompetitionStat(id: number, name: string, typeName: string, typeId:number): void{
    this.statsService.trackPlayerCompetitionStat(id, name, typeName, typeId);
  }

  trackPlayerCompetitionStatHole(id: number): void{
    this.statsService.trackPlayerCompetitionStatHole(id)
  }

  trackPlayerCompetitionStatChangeRound(id:number, round: number): void{
    this.statsService.trackPlayerCompetitionStatChangeRound(id, round)
  }

  trackPlayerCompetitionStatChangeCompetition(newId: number, newName: string, originalId: number, originalName: string, playerId: number, type: number): void{
    this.statsService.trackPlayerCompetitionStatChangeCompetition(newId, newName, originalId, originalName, playerId, type)
  }

  trackPlayerCompetitionStatChangePlayer(playerId: number, playerName: string, originalPlayerId: number, originalPlayerName: string, statId: number, statName: string, type: number): void{
    this.statsService.trackPlayerCompetitionStatChangePlayer(playerId, playerName, originalPlayerId, originalPlayerName, statId, statName, type)
  }

  trackPlayerCompetitionStatActivateRound(id: number, round: number, playerId: number, active: boolean, type: number): void{
    this.statsService.trackPlayerCompetitionStatActivateRound(id, round, playerId, active, type)
  }

  trackPlayerCompetitionStatClickCard(id: number, round: number, playerId: number, areaId: number, type: number): void{
    this.statsService.trackPlayerCompetitionStatClickCard(id, round, playerId, areaId, type)
  }

  trackPlayerCompetitionStatShotByShot(id: number, round: number, hole: number, playerId: number, type: number): void{
    this.statsService.trackPlayerCompetitionStatShotByShot(id, round, hole, playerId, type)
  }

  /** General Practice Service */
  newExercise(exercise: Exercise): void{
    this.generalPracticeService.newExercise(exercise)
  }
  editExercise(exercise: Exercise): void{
    this.generalPracticeService.editExercise(exercise)
  }
  deleteExercise(exercise: Exercise): void{
    this.generalPracticeService.deleteExercise(exercise)
  }
  cloneExercise(exercise: Exercise): void{
    this.generalPracticeService.cloneExercise(exercise)
  }
  clickNewExercise(): void{
    this.generalPracticeService.clickNewExercise();
  }
  clickSessionResults(): void{
    this.generalPracticeService.clickSessionResults();
  }


  clickNewCompetition(): void{
    this.competitionService.clickNewCompetition();
  }

  newCompetition(competition: Competition, start_date: string, course: string, tee: string, scorecard: string): void{
    this.competitionService.newCompetition(competition, start_date, course, tee, scorecard);
  }

  newRound(competition: Competition, holes: number, date: string){
    this.competitionService.newRound(competition, holes, date);
  }
  
  newTeeTime(competition: Competition, course: string, tee: string, scorecard: string, hasChangedCourse: boolean, startingHole: number): void{
    this.competitionService.newTeeTime(competition, course, tee, scorecard, hasChangedCourse, startingHole)
  }

  clickCompetitionTab(tabName: string): void{
    this.competitionService.clickCompetitionTab(tabName)
  }
  
  putCompetitionHole(competition: Competition, hole: number, gross: number, par: number): void{
    this.competitionService.putCompetitionHole(competition, hole, gross, par)
  }

}